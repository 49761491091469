import React,{useContext,useEffect} from 'react';
import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    rem
} from '@mantine/core';
import {
    IconSelector,
    IconCirclePlus,
    IconChevronDown,
    IconChevronUp,
    IconSearch,
    IconPencil,
    IconTrash,
    IconEye,
} from "@tabler/icons-react";
import { AssignPatientfamilycontext } from './context/AssignpatientFamilycontext';
import { BreadCampContext } from "../../../context/BreadCampContext";
import classes from '../../../styles/TableSort.module.css';

function Th({ children, reversed, sorted, onSort }) {
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <Table.Th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}
const PatientList = () => {
    const { patientList, loading,showcreateForm } = React.useContext(AssignPatientfamilycontext)
    const { setBreadCampList } = useContext(BreadCampContext)

    useEffect(() => {
    }, [])
    return (
        <>
        {showcreateForm===false ?
            <ScrollArea>
                {/* <TextInput
            placeholder="Search by any field"
            mb="md"
            leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
            value={search}
            onChange={handleSearchChange}
            style={{ width: '30%' }}
        /> */}
                <Table horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="fixed"
                  style={{ width: "100%" }}
                  striped
                  withColumnBorders
                  stripedColor={"#00526A"}
                  stickyHeader
                  stickyHeaderOffset={-1}
                
                >
                    <Table.Thead style={{ background: "black" }}>
                        <Table.Tr>
                            <Th
                            // sorted={sortBy === 'name'}
                            // reversed={reverseSortDirection}
                            // onSort={() => setSorting('name')}
                            >
                                Sr. No
                            </Th>
                            <Th
                            // sorted={sortBy === 'name'}
                            // reversed={reverseSortDirection}
                            // onSort={() => setSorting('name')}
                            >
                                Patient's Name
                            </Th>
                            <Th
                            // sorted={sortBy === 'email'}
                            // reversed={reverseSortDirection}
                            // onSort={() => setSorting('email')}
                            >
                                Email Address
                            </Th>
                            <Th
                            // sorted={sortBy === 'mobile'}
                            // reversed={reverseSortDirection}
                            // onSort={() => setSorting('mobile')}
                            >
                                Contact Number
                            </Th>
                            <Th
                            // sorted={sortBy === 'mobile'}
                            // reversed={reverseSortDirection}
                            // onSort={() => setSorting('mobile')}
                            >
                               Representative Name
                            </Th>
                            <Th
                            //   sorted={sortBy === 'company'}
                            //   reversed={reverseSortDirection}
                            //   onSort={() => setSorting('company')}
                            >
                                Action
                            </Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {patientList.length > 0 ? (
                             patientList 
                        ) : (
                            <Table.Tr>
                                <Table.Td colSpan={patientList.length}>
                                    <Text fw={500} td="center">
                                        Nothing found
                                    </Text>
                                </Table.Td>
                            </Table.Tr>
                        )}
                    </Table.Tbody>
                </Table>
            </ScrollArea>
            :""}
        </>
    )
}
export default PatientList;