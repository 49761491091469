import { Box, Button, Flex, Checkbox, Space, Table , Group, Loader, Center} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { BreadCampContext } from "../../../context/BreadCampContext";
import BreadCamp from "../../../resuable/BreadCamps";
import { coPhysioLanding } from "../../../constants/constants";
import PhysioService from '../../../services/physioService';
import ProviderService from '../../../services/providerService';
import { useParams  , useNavigate } from "react-router";
import { notifications } from '@mantine/notifications';

const CoPhysioList = () => {
  const params=useParams()
  const navigate = useNavigate()
  const tHead = ["Assign", "Sr No.", "Physiotherapist name", "Email"];
  const { setBreadCampList } = useContext(BreadCampContext);
  const [elementsPhysio, setElementsPhysio] = useState([])
  const [rows, setRows] = useState(null)
  const[loading,setLoading] = useState(false)
  const[isphysiolist , setLoadingPhysiolist] = useState(true)
  const [selectedRows, setSelectedRows] = useState([]);
  const handleUpdate = async() => {
    setLoading(true)
    console.log(selectedRows)
    const apidata={
      doctorId:params.doctorId,
      physiotherapist:selectedRows
    }
    const apicall=await ProviderService.assignphysicoforProvider(apidata)
    if(apicall.status == 200)
      {
        
        setLoading(false)
        notifications.show({
          title: "Success",
          message: "Physiotherapist Assigned successfully to doctor"  ,
      })
        navigate("/CoordinatorDashboard/physio-list")
      }
      
    console.log(apicall)
  };
  const fetchDoctorList=async()=>{
    const apicall= await ProviderService.getProviders()
    console.log(apicall)
    if(apicall.statusCode===200){
          const filterdata=apicall.data.filter(el=>{
            return el._id===params.doctorId
          })
          console.log(filterdata)
          if(filterdata[0].physiotherapist){
            setSelectedRows(filterdata[0].physiotherapist)
          }
    }
  }
  const FetchPhysioList = async () => {
    const apicall = await PhysioService.getPhysioList()
    if (apicall.statusCode === 200) {
      setElementsPhysio(apicall.data)
      apicall.data.forEach((ex, ind) => {
        ex.id = ind
      })
      setLoadingPhysiolist(false)
    //   if (apicall.data.length > 0) {
    //    
    // }   
   }
  }

  useEffect(() => {
    fetchDoctorList()
    FetchPhysioList()
    setBreadCampList([
      {
        title: "Doctor List",
        route: coPhysioLanding,
      },
      {
        title: "Physiotherapist List",
      },
    ]);
  }, []);

  const rowss = [
    {
      id: 0,
      name: "Kathleen Rosales",
      email: "kathleenrosales@aeora.com",
    },
    {
      id: 1,
      name: "Burton Kent",
      email: "burtonkent@aeora.com",
    },
    {
      id: 2,
      name: "Chris Warner",
      email: "chriswarner@aeora.com",
    },
  ].map((element, idx) => {
    return (
      <Table.Tr key={idx}>
        <Table.Td>
          <Checkbox
            aria-label="Select row"
            checked={selectedRows.includes(element.id)}
            onChange={(event) =>
              setSelectedRows(
                event.currentTarget.checked
                  ? {...selectedRows `${element.id}`}
                  : selectedRows.filter((pos) => pos !== element.id)
              )
            }
          />
        </Table.Td>
        {/* <Center> */}
        <Table.Td style={{ textAlign: "center" }}>{element.id}</Table.Td>
        {/* </Center> */}
        <Table.Td>{element.name}</Table.Td>
        <Table.Td>{element.email}</Table.Td>
      </Table.Tr>
    );
  });
  return (
    <>
     <Flex style={{ alignItems: "center", justifyContent: "space-between" }}>
        <div
          style={{
            paddingTop: "25px",
            paddingLeft: "7px",
            paddingBottom: "-10px",
          }}
        >
          <BreadCamp />
        </div>
        <div
          style={{
            paddingTop: "25px",
            paddingRight: "15px",
            paddingBottom: "-10px",
          }}
        >
         {/* <Group align="flex-end" > */}
       
       <Button bg={"cyan"} m={16} loading={loading}  onClick={() => handleUpdate()}>
         Update
       </Button>
       {/* </Group> */}
        </div>
      </Flex>
      
      <Space h={"md"} />
      {isphysiolist ?  <Center>
        <Loader/>
      </Center> 
       :  <Box style={{ height: "70vh", overflow: "auto", margin: "20px" }}>
       
      
       <Table
         style={{ width: "100%" }}
         striped
         withColumnBorders
         stripedColor={"#00526A"}
         stickyHeader
         stickyHeaderOffset={-1}
       >
         <Table.Thead style={{ background: "black" }}>
           <Table.Tr>
             {tHead.map((items) => {
               return (
                 <Table.Th
                   key={items}
                   c={"white"}
                   style={{ textAlign: "center" }}
                 >
                   {items}
                 </Table.Th>
               );
             })}
           </Table.Tr>
         </Table.Thead>
         {elementsPhysio.length>0 ?
           <Table.Tbody style={{ overflowY: "auto" }}>{
             elementsPhysio.map((element, idx) => {
               return (
                 <Table.Tr key={idx}>
                   <Table.Td>
                   <Checkbox
                   aria-label="Select row"
                   checked={selectedRows.includes(element._id)}
                   onChange={(event) =>
                     setSelectedRows(
                       event.currentTarget.checked
                         ? [...selectedRows, element._id]
                         : selectedRows.filter((pos) => pos !== element._id)
                     )
                   }
                 />
                   </Table.Td>
                   {/* <Center> */}
                   <Table.Td style={{ textAlign: "center" }}>{idx + 1}</Table.Td>
                   {/* </Center> */}
                   <Table.Td>{element.name}</Table.Td>
                   <Table.Td>{element.email}</Table.Td>
                 </Table.Tr>
               );
             })
           }</Table.Tbody>
           : ""}
       </Table>
     </Box> }
      
      
    </>
  );
};

export default CoPhysioList;
