import { Box, Button, Center, Loader, Paper, Space, Table  , Text, Title} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { BreadCampContext } from "../../../context/BreadCampContext";
import BreadCamp from "../../../resuable/BreadCamps";
import { coPhysioLanding } from "../../../constants/constants";
import PhysioService from '../../../services/physioService';
import ProviderService from '../../../services/providerService';
import { useParams } from "react-router";

const CoViewPhysio = () => {
  const params=useParams()
  const tHead = ["Sr No.", "Physiotherapist name", "Email"];
  const { setBreadCampList } = useContext(BreadCampContext);
  const [elementsPhysio, setElementsPhysio] = useState([])
  const [rows, setRows] = useState(null)
  const [selectedRows, setSelectedRows] = useState([]);
  const[loading , setLoading] = useState(true)
  const fetchDoctorList=async()=>{
    const apicall= await ProviderService.getProviders()
    console.log(apicall)
    if(apicall.statusCode===200){
          const filterdata=apicall.data.filter(el=>{
            return el._id===params.doctorId
          })
          console.log(filterdata)
          if(filterdata[0].physiotherapist){
            setSelectedRows(filterdata[0].physiotherapist)
            setLoading(false)
          }
    }
  }
  const FetchPhysioList = async () => {
    const apicall = await PhysioService.getPhysioList()
    if (apicall.statusCode === 200) {
      setElementsPhysio(apicall.data)
      apicall.data.forEach((ex, ind) => {
        ex.id = ind
      })
    }
  }

  useEffect(() => {
    fetchDoctorList()
    FetchPhysioList()
    setBreadCampList([
      {
        title: "Doctor List",
        route: coPhysioLanding,
      },
      {
        title: "Physiotherapist List",
      },
    ]);
  }, []);

 
  return (
    <>
      <BreadCamp />
      <Space h={"md"} />
      <Box style={{ height: "70vh", overflow: "auto", margin: "20px" }}>
        
          {  loading ? <Center>
        <Loader/>
      </Center> : selectedRows.length>0 ?
          <Table
          style={{ width: "100%" }}
          striped
          withColumnBorders
          stripedColor={"#00526A"}
          stickyHeader
          stickyHeaderOffset={-1}
        >
          <Table.Thead style={{ background: "black" }}>
            <Table.Tr>
              {tHead.map((items) => {
                return (
                  <Table.Th
                    key={items}
                    c={"white"}
                    style={{ textAlign: "center" }}
                  >
                    {items}
                  </Table.Th>
                );
              })}
            </Table.Tr>
          </Table.Thead>
            <Table.Tbody style={{ overflowY: "auto" }}>{
              elementsPhysio.map((element, idx) => {
                return (
                  <Table.Tr key={idx}>
                  
                    {/* <Center> */}
                    <Table.Td style={{ textAlign: "center" }}>{idx + 1}</Table.Td>
                    {/* </Center> */}
                    <Table.Td>{element.name}</Table.Td>
                    <Table.Td>{element.email}</Table.Td>
                  </Table.Tr>
                );
              })
            }</Table.Tbody>
            </Table>
            : <>
            
             <Center>
              
              <Text order={5} c={'#fff'}>  No Physio Assigned to this doctor </Text>
              
          

            </Center>
           
            </>}
        
      </Box>
      
    </>
  );
}

export default CoViewPhysio;
