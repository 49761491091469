import React, { useContext } from "react";
import { Breadcrumbs, Anchor } from "@mantine/core";
import { BreadCampContext } from "../context/BreadCampContext";
import { useNavigate } from "react-router-dom";
const BreadCampFunctionclick = () => {
  const { breadCampList } = useContext(BreadCampContext);
  return (
    <>
      {breadCampList.length > 0 ? (
        <Breadcrumbs>
          {breadCampList.map((item, index) => (
            <Anchor c="#B3F9FF" onClick={item.handleclick} key={index}>
              {item.title}
            </Anchor>
          ))}
        </Breadcrumbs>
      ) : (
        ""
      )}
    </>
  );
};
export default BreadCampFunctionclick;
