import React, { useContext, useEffect } from 'react';
import { Container, Grid } from '@mantine/core';
import BreadCampFunctionclick from "../../../resuable/BreadCampsFunction";
import PatientList from './PatientList';
import AssignPatientFamilyprovider from './context/AssignpatientFamilycontext';
import AddPatientFamilyPage from '../AssignPatientFamily/AddPatientFamilyPage';
const AssignPatientFamily = () => {
    return (
        <>
            <AssignPatientFamilyprovider >
                <Container fluid={true}>
                    <Grid>
                        <Grid.Col><BreadCampFunctionclick /></Grid.Col>
                        <Grid.Col>
                                <AddPatientFamilyPage />
                                
                                <PatientList />
                            
                        </Grid.Col>
                    </Grid>
                </Container>
            </AssignPatientFamilyprovider>

        </>
    )
}

export default AssignPatientFamily;