
import React, { useEffect,useContext } from 'react'
import {
    Box,
    Container,
    Grid, NumberInput,
    Stack, Select,
    Flex,
    Group, Radio, MultiSelect,
    TextInput,
    Button, Center,
    Paper,
    Text,
} from "@mantine/core";
import { DateInput } from '@mantine/dates';
import { useForm } from "@mantine/form";
import { useParams } from 'react-router-dom';
import patientService from '../../../services/patientService';
// import heightOptions from '../../../utills/';
import BreadCamp from "../../../resuable/BreadCamps";
import { navbarContext } from "../Context/CoordinatorDashboard";
import { BreadCampContext } from "../../../context/BreadCampContext";
import {IconCirclePlus} from '@tabler/icons-react';

const AddNewPatientForDataCollect = () => {
    const params = useParams()
    const { doctorId } = params;
    const { setBreadCampList } = useContext(BreadCampContext);
    const { setNavActive } = useContext(navbarContext);
    const form = useForm({
        mode: "uncontrolled",
        initialValues: {
            name: "", email: "", mobile: "",
            weight: "",
            height: "",
            dateOfBirth: "",
            gender: "",
            dateOfSurgery: "",
            preConditions: [],
            suregerysubtype: [],
            city: "",
            Country: "",
            SureryType: "",
            password: "",
            dob: "",
            address: "",
        },

        validate: {
            //   email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
        },
    });
    const CreatePatient = async (values) => {
        const createPatientdata = {
            values: {
                email: values.email,
                name: values.name,
                password: values.password,
                mobileNumber: values.mobile,
                doctorId: doctorId,

            }
        }
        const initialhealth = {
            email: values.email,
            surgerydate: values.dateOfSurgery,
            gender: values.gender,
            surgerytype: values.SureryType,
            height: values.height,
            weight: values.weight,
            Preexistingcon: values.preConditions,
            operationtype: "",
            address: values.address,
            city: values.city,
            surgerysubtype: values.suregerysubtype,
            dob: values.dob
        }
        const apicall = await patientService.createPatient(createPatientdata)
        console.log(apicall)
        if (apicall.status === 201) {
            // update inital health
            const apicall = await patientService.postPatientQuestionAnswerfordatacollector(initialhealth)
            console.log(apicall)
            if (apicall?.status === 201) {
                navigate(`/CoordinatorDashboard/doctorlist/${doctorId}`)
            }
        }

    }
    useEffect(()=>{
        setBreadCampList([
            {
              title: "Patient List",
              route:    `/CoordinatorDashboard/doctorlist/${doctorId}`,
            },
            { title: "Create Patient" },
          ]);
          setNavActive("Doctor");
    },[])
    return (
        <>
            <Flex style={{ alignItems: "center", justifyContent: "space-between" }}>
                <div
                    style={{
                        paddingTop: "25px",
                        paddingLeft: "7px",
                        paddingBottom: "-10px",
                    }}
                >
                    <BreadCamp />
                </div>
                <div
                    style={{
                        paddingTop: "25px",
                        paddingRight: "15px",
                        paddingBottom: "-10px",
                    }}
                >
                    {/* <Button mr={10} onClick={() => handleClick()}
                        leftSection={<IconCirclePlus size={20} />}
                    >
                        Create New Patient
                    </Button>

                    <Button
                        onClick={ClickCreatePatient}
                        fw={600}
                        leftSection={<IconCirclePlus size={20} />}
                        variant="default"
                    >
                        Create  Patient For Data Collector
                    </Button> */}
                </div>
            </Flex>
            <Box
                style={{
                    height: "70vh",
                    overflow: "auto",
                    margin: "20px",
                    // border: "2px solid white",
                }}
            >
                <form onSubmit={form.onSubmit((values) => CreatePatient(values))}>
                    <Grid>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6, sm: 12 }}>
                            <TextInput
                                withAsterisk
                                label="First Name"
                                placeholder="Enter Patient's First Name"
                                {...form.getInputProps("name")}
                                styles={{
                                    label: { color: '#7E8CA0' },
                                    inner: { fontSize: 20 },
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6, sm: 12 }}>
                            <TextInput
                                withAsterisk
                                label="Email"
                                placeholder="Enter Email"
                                {...form.getInputProps("email")}
                                styles={{
                                    label: { color: '#7E8CA0' },
                                    inner: { fontSize: 20 },
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6, sm: 12 }}>
                            <TextInput
                                withAsterisk
                                label="Mobile No"
                                placeholder="Enter Mobile No"
                                {...form.getInputProps("mobile")}
                                styles={{
                                    label: { color: '#7E8CA0' },
                                    inner: { fontSize: 20 },
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6, sm: 12 }}>

                            <NumberInput
                                withAsterisk
                                label="Weights"
                                placeholder="Enter Body weight in Kg"
                                {...form.getInputProps("weight")}
                                styles={{
                                    label: { color: '#7E8CA0' },
                                    inner: { fontSize: 20 },
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                            <NumberInput
                                withAsterisk
                                label="Height"
                                placeholder="Enter Body Height in Cm"
                                {...form.getInputProps("height")}
                                styles={{
                                    label: { color: '#7E8CA0' },
                                    inner: { fontSize: 20 },
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6, sm: 12 }}>
                            <DateInput
                                withAsterisk
                                label="Date Of Birth"
                                placeholder="May 21 2024"
                                {...form.getInputProps("dob")}
                                styles={{
                                    label: { color: '#7E8CA0' },
                                    inner: { fontSize: 20 },
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6, sm: 12 }}>
                            <Select
                                withAsterisk
                                label="Gender"
                                placeholder="Knee"
                                data={['MALE', 'FEMALE']}
                                {...form.getInputProps("gender")}
                                styles={{
                                    label: { color: "blue" },
                                    inner: { fontSize: 20 },
                                }}

                            />
                            {/* <Flex>
                            <Radio label="Male" value="male"    {...form.getInputProps("gender")} />
                            <Radio label="Female" value="female"    {...form.getInputProps("dateOfSurgery")} />
                        </Flex> */}
                        </Grid.Col>
                    </Grid>
                    {/* <Group justify="flex-end" mt="md">
    <Button type="submit">Submit</Button>
  </Group> */}
                    <Text c="#37B5B6" fw={700} styles={{ fontFamily: "Greycliff CF" }}>
                        Surgery  Detail
                    </Text>
                    <Grid>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6, sm: 12 }}>

                            <DateInput
                                withAsterisk
                                label="Date Of Birth"
                                placeholder="May 21 2024"
                                {...form.getInputProps("dateOfSurgery")}
                                styles={{
                                    label: { color: '#7E8CA0' },
                                    inner: { fontSize: 20 },
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6, sm: 12 }}>
                            <Select
                                withAsterisk
                                label="Surgery Type"
                                placeholder="Knee"
                                {...form.getInputProps("SureryType")}
                                styles={{
                                    label: { color: "blue" },
                                    inner: { fontSize: 20 },
                                }}
                                data={['Knee']}

                            />

                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6, sm: 12 }}>

                            <MultiSelect
                                withAsterisk
                                label="Pre Existing Condtions"
                                placeholder="Pick value"
                                data={['Diabetic', 'Cancer', 'Asthma']}
                                {...form.getInputProps("preConditions")}
                                styles={{
                                    label: { color: '#7E8CA0' },
                                    inner: { fontSize: 20 },
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6, sm: 12 }}>

                            <MultiSelect
                                withAsterisk
                                label="Surery Sub Type"
                                placeholder="Select Surgery type"
                                {...form.getInputProps("suregerysubtype")}
                                styles={{
                                    label: { color: '#7E8CA0' },
                                    inner: { fontSize: 20 },
                                }}
                                data={['Left Knee', 'Right Knee']}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6, sm: 12 }}>
                            <TextInput
                                withAsterisk
                                label="Address"
                                placeholder="Enter Address"
                                {...form.getInputProps("address")}
                                styles={{
                                    label: { color: '#7E8CA0' },
                                    inner: { fontSize: 20 },
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6, sm: 12 }}>
                            <TextInput
                                withAsterisk
                                label="City Name"
                                placeholder="Select Patient's City"
                                {...form.getInputProps("city")}
                                styles={{
                                    label: { color: "blue" },
                                    inner: { fontSize: 20 },
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6, lg: 6, sm: 12 }}>
                            <TextInput
                                withAsterisk
                                label="Password"
                                placeholder="Password"
                                {...form.getInputProps("password")}
                                styles={{
                                    label: { color: "blue" },
                                    inner: { fontSize: 20 },
                                }}
                            />
                        </Grid.Col>

                    </Grid>
                    <Group  >
                        <Center>
                            <Button m={20}>Cancel </Button>
                            <Button m={20} type="submit" >Submit</Button>
                        </Center>

                    </Group>

                </form>
            </Box>



        </>
    )
}

export default AddNewPatientForDataCollect;