import Cookies from "universal-cookie";
import Axios from "../helper/patientHelper";
import { BASEURL } from "../constants/constants";

const axios = new Axios();
const cookies = new Cookies();
const baseUrl = BASEURL;

class TrackerService {
  getemail() {
    const option = {
      url: `${baseUrl}/patientdetail`,
    };

    return axios.get(option);
  }
  getemailbyid(data) {
    const option = {
      url: `${baseUrl}/patientdetail/${data}`,
    };

    return axios.get(option);
  }
}
export default TrackerService;