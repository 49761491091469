import Cookies from "universal-cookie";
import axios from "axios";
 
const cookies = new Cookies();
export default class Axios {
  get(options) {
    options.method = "get";
    options.headers = this.getHeaders();
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
        
          if(response.status===200 && response.data.code===190){
            window.location.replace(`${window.location.origin}${response.data.redireturl}`)
            // navigate(`${response.data.redireturl}`)
          }
          else{
            resolve(response.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
 
  post(options) {
    options.method = "POST";
    options.headers = options?.headers ? options.headers : this.getHeaders();
    // options.headers[ "Access-Control-Allow-Origin"] = "*"
 
    return axios(options)
      .then((response) => {
        if(response.status===200 && response.data.code===190){
          console.log("jjkdsjesio ioj eio")
          window.location.replace(`${window.location.origin}${response.data.redireturl}`)
          // navigate(`${response.data.redireturl}`)
        }
        else{
          return response;
        }
      })
      .catch((response) => {
        return response.response;
      });
  }
 
  upload(options) {
    options.method = "POST";
    options.headers = this.getHeaders();
    options.headers["content-type"] = "multipart/form-data";
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          if(response.status===200 && response.data.code===190){
            window.location.replace(`${window.location.origin}${response.data.redireturl}`)
            // navigate(`${response.data.redireturl}`)
          }
          else{
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
 
  patch(options) {
    options.method = "PATCH";
    options.headers = this.getHeaders();
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          if(response.status===200 && response.data.code===190){
             window.location.replace(`${window.location.origin}${response.data.redireturl}`)
            // navigate(`${response.data.redireturl}`)
          }
          else{
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
 
  put(options) {
    options.method = "PUT";
    options.headers = this.getHeaders();
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          if(response.status===200 && response.data.code===190){
             window.location.replace(`${window.location.origin}${response.data.redireturl}`)
            // navigate(`${response.data.redireturl}`)
          }
          else{
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
 
  delete(options) {
    options.method = "DELETE";
    options.headers = this.getHeaders();
    return new Promise((resolve, reject) => {
      axios(options)
        .then((response) => {
          if(response.status===200 && response.data.code===190){
            window.location.replace(`${window.location.origin}${response.data.redireturl}`)
            // navigate(`${response.data.redireturl}`)
          }
          else{
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
 
  getHeaders() {

    return {
      Authorization: `Bearer ${cookies.get("admin") || undefined}`,
    };
  }
}