import {
  Box,
  Button,
  FileButton,
  Flex,
  Group,
  Image,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import CameraComponent from "./CameraComponent";
import camera from "./Icons/camera.svg";
import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import HookSteper from "../../pages/PatientDashBoard/Hooks/HookSteper";

const ImageCard = ({
  LabelInfo,
  dataImg,
  storeImgData,
  resUpdate,
  selectedChoice,
}) => {
  console.log("selectedChoice ", selectedChoice);
  const [opened, { open, close }] = useDisclosure(false);
  const[upload , setupload] = useState(false)
  const navigate = useNavigate();
  // const [img, setImg] = useState(dataImg);
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);
  const { title, desc } = LabelInfo;
  const { postSurgery } = HookSteper();

  const convertFile = (imgFile) => {
    //convert to base64
    let reader = new FileReader();
    reader.readAsDataURL(imgFile);

    reader.onload = () => {
      resUpdate(1);
      storeImgData(reader.result);
      if (fileRef.current) {
        fileRef.current.value = "";
      }
    };

    reader.onerror = () => {
      alert("Please Check the file type");
    };
  };

  return (
    <Paper
      withBorder
      shadow="lg"
      radius={"md"}
      p={"sm"}
      bg="white"
      w="50vh"
      // h={"30vh"}
      // h={"40vh"}
    >
      <Flex>
        <Group>
          <Text c="black" fw={700}>
            {title}
          </Text>
          {/* <Divider size="xs" orientation="vertical" /> */}
          <Text c="red" fw={700}>
            {desc}
          </Text>
          <Paper
            radius={"md"}
            p={"xl"}
            w={"100%"}
            withBorder
            variant="dashed"
            onClick={() => {
              storeImgData("");
              if (selectedChoice === "Click Image") {
                open();
              }
            }}
            h={200}
          >
            {!dataImg && (
              <Text c={"black"} ta={"center"}>
                {selectedChoice === "Upload" ? "Click on Upload button to select Image" : "Click here to open Camera "} 
              </Text>
            )}
            <Image
              fit="contain"
              radius={"sm"}
              src={!dataImg ? camera : dataImg}
              alt="camera"
              h={140}
            />
          </Paper>

          {dataImg && (
            <Button
              c={"black"}
              onClick={() => {
                storeImgData("");
                resUpdate(0);
              }}
            >
              Re-do
            </Button>
          )}

          {!dataImg && (
            <div style={{ display: "flex" }}>
              {selectedChoice === "Upload" ? (
                <FileButton
                  ref={fileRef}
                  value={file}
                  onChange={(file) => {
                    setFile(file);
                    convertFile(file);
                    resUpdate(1);
                  }}
                  accept="image/png,image/jpeg"
                >
                  {(props) => (
                    <Button onClick={() => setupload(true)} c={"black"} {...props}>
                      Upload
                    </Button>
                  )}
                </FileButton>
              ) : (
                <Button
                  c={"black"}
                  onClick={() => {
                    open();
                  }}
                >
                  Click Image
                </Button>
              )}

              {/* <Space w="md" /> */}
              {/* <Button
                c={"black"}
                onClick={() => {
                  open();
                }}
              >
                Click Image
              </Button> */}
            </div>
          )}

          <Button
            c={"black"}
            onClick={() => {
              navigate(postSurgery[1].route);
              // navigate("/patientDashboard/pain-data");
              // storeImgData("");
              // resUpdate(0);
            }}
          >
            Skip
          </Button>
        </Group>
      </Flex>
      <CameraComponent
        dataImg={dataImg}
        setImg={storeImgData}
        open={open}
        opened={opened}
        close={close}
        checkUpdate={resUpdate}
      />
    </Paper>
  );
};

export default ImageCard;
ImageCard.propTypes = {
  LabelInfo: PropTypes.object,
  // setDataImg: PropTypes.string.isRequired,
  dataImg: PropTypes.string.isRequired,
  storeImgData: PropTypes.func.isRequired,
  resUpdate: PropTypes.func,
  selectedChoice: PropTypes.string.isRequired,
};
