import {
  Button,
  Center,
  Container,
  Grid,Notification,
  PasswordInput,Alert,
  Space,Box,Stack,Loader,
  TextInput,rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {useState } from "react"
import coordinatorUsersService from "../../../services/CoordinatorUsersService";
import { IconX, IconCheck } from "@tabler/icons-react";

const AddNewPatient = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const { doctorId } = useParams();
  const [notificationContent, setNotificationContent] = useState({}); // State to manage notification content
  const [notificationOpen, setNotificationOpen] = useState(false);
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;
  const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      contact: "",
      password: "",
    },

    validate: {
      name: (value) => (value ? null : "Patient name is required"),
      email: (value) =>
        /^\S+@\S+\.\S+$/.test(value)
          ? null
          : "Invalid email eg. someone@example.com",
      contact: (value) =>
        /^\d{10}$/.test(value) ? null : "Contact must be 10 digits",
      password: (value) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!])[A-Za-z\d@$!]{6,}$/.test(value)
          ? null
          : "Password must be at least 6 characters, include an uppercase letter, a lowercase letter, and one of the following symbols: @, $, !",
    },
  });

  const handleClick = async (e) => {
    e.preventDefault();
    form.validate();
  
    if (form.isValid()) {
      setLoading(true)
      setDisable(true)
            const formData = {
        name: form.values.name,
        email: form.values.email,
        mobile: form.values.contact,
        password: form.values.password,
        doctorId: doctorId,
      };
 try {
  const response =  await coordinatorUsersService.createPatient(formData);
  if (response.status == 201) {
    setNotificationContent({
      icon: checkIcon,
      color: "teal",
      title: "Success!",
      message: "You have successfully created new Coordinator Profile",
    });
    setNotificationOpen(true);
    setTimeout(() => {
      navigate(`/CoordinatorDashboard/doctorlist/${doctorId}`, { replace: true });
    }, 900);
  }
 else if(response.data.statusCode == 400)
    {
      setNotificationContent({
        icon: xIcon,
        color: "red",
        title: "Failed",
        message: response?.data.message,
      });
      setNotificationOpen(true);
     setLoading(false)
    }
    else {
    setLoading(false);
    setDisable(false)
    navigate(`/CoordinatorDashboard/doctorlist/${doctorId}`);
    }
 } catch (error) {
 setLoading(false)
 setNotificationOpen(true);
 setNotificationContent({
  icon: xIcon,
  color: "red",
  title: "Failed",
  message: error,
});
  console.log(error);
 }
   
    }
  };

  return (
    <>
    <Stack>
    <Container my={"30"}   >

    <form onSubmit={form.onSubmit(console.log)} method="post">

      <Center>
        <Space h={"md"}>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Patient Name"
                required
                {...form.getInputProps("name")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Email"
                required
                {...form.getInputProps("email")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Contact"
                required
                {...form.getInputProps("contact")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <PasswordInput
                label="Password"
                required
                {...form.getInputProps("password")}
              />
            </Grid.Col>
          </Grid>
          <Space h={"lg"} />
          <Center>
            <Button
              loading={loading}
              onClick={handleClick}
              style={{ background: "#21A18E", color: "white" }}
            >
               {loading ? <Loader /> : "Submit"}
            </Button>
          </Center>
        </Space>
      </Center>
       </form>
       {notificationOpen && (
        <Alert styles={{
          root: { backgroundColor: '#ffff' },
          label: { color: notificationContent.color },
          inner: { fontSize: 20 },
        }}  style={{'marginTop' :'240px'}} color={notificationContent.color}
        title={notificationContent.title}  icon={notificationContent.icon} withCloseButton  onClose={() => setNotificationOpen(false)}>
       
        {notificationContent.message}
      </Alert>
       
        )}
    </Container>
    {/* <Container>
      {notificationOpen && (
        <Alert  color={notificationContent.color}
        title={notificationContent.title}  icon={notificationContent.icon} withCloseButton  onClose={() => setNotificationOpen(false)}>
       
        {notificationContent.message}
      </Alert>
       
        )}
    </Container> */}
    </Stack>
    </>
  );
};

export default AddNewPatient;
