import React, { useEffect } from 'react';
import { Button, Center, Checkbox, Group, TextInput,MultiSelect } from '@mantine/core';
import { useForm } from '@mantine/form';
import DataCollectorService from '../../../services/dataCollectorServices'

const AssignPatientFormDataCollector = (props) => {
    const form = useForm({
        // mode: 'uncontrolled',
        initialValues: {
            name:"",
            patientId: [],
        },

        validate: {
          //  email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });
    useEffect(()=>{
        form.setFieldValue("name",props.name)
        if(props.ids.length>0){
            form.setFieldValue("patientId",props.ids)
        }
    },[])
    const updatepatient = async(values)=> {
        console.log(values)
        const apidata={
            patientid:values.patientId,
            dataCollectorId:props.collectorid
        }
        const apicall = await DataCollectorService.assignpatientfordatacollector(apidata)
        console.log(apicall)
    }
    return (
        <>
            <Center w={"40%"} m="auto">
                <form style={{width:'100%'}} onSubmit={form.onSubmit((values)=>updatepatient(values))}>
                    <TextInput
                        readOnly
                        disabled
                        withAsterisk
                        label="Data Collector Nmae"
                        placeholder=""
                        {...form.getInputProps('name')}
                    />
                    <MultiSelect
                        label="Select Patient"
                        placeholder="Select Patient"
                        data={props.list}
                       // data={['React', 'Angular', 'Vue', 'Svelte']}
                        {...form.getInputProps('patientId')}
                    />
                    <Group justify="flex-end" mt="md">
                    <Button type="submit" onClick={()=>props.setPatientListshow(true)}>Back Data Collector List</Button>
                        <Button type="submit">{props.ids.length>0 ?"Update":"Submit"}</Button>
                    </Group>
                </form>
            </Center>
        </>
    )
}

export default AssignPatientFormDataCollector;