import { useEffect, useState, useContext } from "react";
import {
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem,
  keys,
  Grid,
  Button,
  Tooltip,
} from "@mantine/core";
import {
  IconSelector,
  IconCirclePlus,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
  IconPencil,
  IconTrash,
  IconEye,
} from "@tabler/icons-react";
// import PatientService from "../../../services/patientService";
import TableData from "./TableData";
import BreadCamp from "../../resuable/BreadCamps";
import { Link, useNavigate } from "react-router-dom";
import { navbarContext } from "./context/DataCollectorDashboard";
// import { PatientDetailoverview } from "../../../constants/constants";
// import dailyReportService from "../../../services/dailyReportService";
import moment from "moment";
import { BreadCampContext } from "../../context/BreadCampContext";
import LoaderPage from '../../resuable/loder';
function OverViewDataCollector(props) {
  const { setBreadCampList } = useContext(BreadCampContext);
  const { setNavActive } = useContext(navbarContext);
  const [sortedData, setSortedData] = useState([]);
  const [list, setList] = useState([]);
  const [rows, setRows] = useState([]);
  const [patientListshow, setPatientListshow] = useState(true);
  const [createpatientshow, setCreatepatientshow] = useState(false);
  const navigate = useNavigate();
  const [loding,setLoading]=useState(true)

  const [breadcampitems, setBreadcampitems] = useState([
    // { title: "DashBoard", href: "#" },
    { title: "Overview", href: "#" },
  ]);

 useEffect(() => {
    setBreadCampList([{ title: "Dashboard" }]);
    setNavActive("Dashboard");
    // FetchPatientList();
  }, []);

  const dataObject = {
    key1: "value1",
    key2: "value2",
  };

 
  const ClickCreatePatient = () => {
    navigate("/doctorDashboard/add-patient");
  };
  return (
    <>
    <Grid>
      <Grid.Col>
        <Group style={{ justifyContent: "space-between" }}>
          <BreadCamp />
        </Group>
      </Grid.Col>
      {/* {loding===false ?
      <Grid.Col>
        {patientListshow === true ? (
          <TableData
            list={list}
            rows={rows}
            sortedData={sortedData}
            setSortedData={setSortedData}
          />
        ) : (
          ""
        )}
      </Grid.Col>
      :
      <Grid.Col>
        <LoaderPage />
      </Grid.Col>
      } */}
    </Grid>
    </>
  );
}
export default OverViewDataCollector;
