import React, { useEffect, useState, useContext } from 'react';
import PatientService from '../../../../services/patientService';
import { Table, Group, Tooltip, Text } from '@mantine/core';
import { IconTrash, IconPlus,IconEdit } from '@tabler/icons-react';
import patientFamilyService from '../../../../services/patientFamilyService';
import { BreadCampContext } from "../../../../context/BreadCampContext";
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';

export const AssignPatientfamilycontext = React.createContext();

const AssignPatientFamilyprovider = ({ children }) => {
    const { setBreadCampList } = useContext(BreadCampContext)

    const [patientList, setPatientList] = useState([])
    const [loading, setloading] = useState(true);
    const [createlodar, setCreatelodar] = useState(false);
    const [showcreateForm, setShowcreateform] = useState(false)
    const [patientDetail, setPatientDetal] = useState();
    const [familyDetail, setFamilyDetail] = useState();
    const [submitformLoading,setSubmitFormLoading]=useState(false)
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            patientname: patientDetail?.name, name: "", email: "", mobile: "", password: "",updatepassword:""
        },
        validate: {
            // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            name: (value) => (value.length < 2 ? 'This Field is Required!' : null),
            email: (value) => (/^\S+@\S+$/.test(value) ? null : value ===""? "Field Is required": 'Invalid email'),
            mobile: (value) => (value.length < 10 ? value==="" ? "This Field is Required!": 'Mobile number must be 10 digit long' : null),
            password: (value) => (value < 10 ? 'You must be at least  to register' : null)

        },
    });
    const updateform = useForm({
        mode: 'uncontrolled',
        initialValues: {
            patientname: patientDetail?.name, name: "", email: "", mobile: "",updatepassword:""
        },
        validate: {
            // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            name: (value) => (value.length < 2 ? 'This Field is Required!' : null),
            email: (value) => (/^\S+@\S+$/.test(value) ? null : value ===""? "Field Is required": 'Invalid email'),
            mobile: (value) => {
                if (value === "") return "This Field is Required!";
                if (!/^\d+$/.test(value)) return "Mobile number must contain only digits";
                if (value.length !== 10) return "Mobile number must be 10 digits long";
                return null;
            },
        },
    });
    const handleclickbreadcumps = () => {
        console.log("alert", showcreateForm)
        setShowcreateform(false)
    }
    const inactiveclick = () => {

    }
    const DeletePatientFamily = async(patientfamilyid)=>{
        console.log(patientfamilyid)
        const apicall = await  patientFamilyService.deletePatientFamily(patientfamilyid)
        console.log(apicall)
        if(apicall.statusCode===200){
            // show notification
            FetchPatientList()
        }
    }
    const fetchFamilydetailbyPatientId = async (patientid) => {
        setFamilyDetail()
        form.reset()
        const requestdata = {
            patientId: patientid
        }
        const apicall = await patientFamilyService.getPatientfamilymemberDetail(requestdata)
        console.log(apicall)
        if (apicall.status === 200) {
            if (apicall?.data?.data) {
                const restdata = apicall.data.data
                const setdata = {
                    familyuserid: restdata?._id ? restdata?._id : "",
                    name: restdata?.name ? restdata.name : "",
                    email: restdata?.email ? restdata.email : "",
                    mobile: restdata?.mobile ? restdata.mobile : "",
                    patientId: restdata?.patientId ? restdata.patientId : ""
                }
                updateform.setFieldValue("name", setdata.name)
                updateform.setFieldValue("email", setdata.email)
                updateform.setFieldValue("mobile", setdata.mobile)
                setFamilyDetail(setdata)
            }
        }
    }
    const deletePatientFamilyUser = (detail) => {
        // delete PatientFamily User by id
        modals.openConfirmModal({
            title: `Delete ${detail.patientfamily_name}`,
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to delete Representative {detail.patientfamily_name}?
                </Text>
            ),
            labels: { confirm: 'Delete Representative', cancel: "No don't delete it" },
            confirmProps: { color: 'red' },
            onCancel: () => console.log('Cancel'),
            onConfirm: () => {DeletePatientFamily(detail.patientfamilyId)},
        })
    }
    const FetchPatientList = async () => {
        const apicall = await PatientService.getallPatients()
        if (apicall?.statusCode === 200) {
            if (apicall.data.length > 0) {
                let setdata = []
                apicall.data.forEach((ec) => {
                    const dataset = {
                        name: ec?.name ? ec?.name : "",
                        email: ec?.email ? ec.email : "",
                        mobile: ec?.mobile ? ec?.mobile : "",
                        patientid: ec?._id ? ec?._id : ""
                    }
                    if (ec.patientfamily.length > 0) {
                        dataset["patientfamily_name"] = ec.patientfamily[0].name,
                            dataset["patientfamilyId"] = ec.patientfamily[0]._id,
                            dataset["patientfamilyemail"] = ec.email
                    }
                    else {
                        dataset["patientfamily_name"] = "",
                            dataset["patientfamilyId"] = "",
                            dataset["patientfamilyemail"] = ""
                    }

                    setdata.push(dataset)
                })
                const rowdata = setdata.map((row, index) => (
                    <Table.Tr key={index}>
                        <Table.Td style={{width:"10%"}}>{index + 1}</Table.Td>
                        <Table.Td style={{width:"10%"}}>
                            {row.name}
                        </Table.Td>
                        <Table.Td style={{width:"10%"}}>
                            {row.email}
                        </Table.Td>
                        <Table.Td style={{width:"10%"}}>
                            {row.mobile}
                        </Table.Td>
                        <Table.Td style={{width:"10%"}}>
                            {row.patientfamily_name !=="" ? row.patientfamily_name :"N/A"}
                        </Table.Td>
                        <Table.Td style={{width:"10%"}}>
                            <Group align="">
                                <Tooltip label="Update Patient Family">
                                    {row.patientfamilyId !== "" ?
                                        <IconEdit
                                            onClick={() => {
                                                setFamilyDetail(), setShowcreateform(true),
                                                    setFamilyDetail()
                                                setPatientDetal(row),
                                                    fetchFamilydetailbyPatientId(row.patientid)
                                                setBreadCampList([{ title: "Patient List", handleclick: handleclickbreadcumps }, { title: "Assign Patient Family", handleclick: inactiveclick }]);

                                            }}
                                            size={18}
                                            color="#90EE90"
                                        />
                                        :
                                        <IconPlus
                                            onClick={() => {
                                                setFamilyDetail(), setShowcreateform(true),
                                                    setFamilyDetail()
                                                setPatientDetal(row),
                                                    fetchFamilydetailbyPatientId(row.patientid)
                                                setBreadCampList([{ title: "Patient List", handleclick: handleclickbreadcumps }, { title: "Assign Patient Family", handleclick: inactiveclick }]);

                                            }}
                                            size={18}
                                            color="#90EE90"
                                        />
                                    }
                                </Tooltip>
                                {row.patientfamilyId !== "" ?
                                <Tooltip label="Delete Patient Family">
                                    <IconTrash onClick={() => deletePatientFamilyUser(row)}
                                        size={18}
                                        color="red"
                                    />
                                </Tooltip>
                                :""}

                            </Group>
                        </Table.Td>
                    </Table.Tr>
                ));
                setPatientList(rowdata)
                setTimeout(() => {
                    setloading(false)
                }, 500)
            }
        }
    }

    const createPatientFamily = async (value) => {
        console.log(value)
        setCreatelodar(true)

        if (familyDetail) {
            // update code 
            const apidata = {
                name: value.name,
                email: value.email,
                mobile: value.mobile,
                patientId: patientDetail.patientid,
                patientfamilyid: familyDetail.familyuserid,
                updatepassword:value.updatepassword
            }
            const apicall = await patientFamilyService.updatePatientfamilymemberDetail(apidata)
            console.log(apicall)
            if (apicall.status === 200) {
                notifications.show({
                    title: 'Update Patient Family',
                    message: 'Update Success!',
                })
                FetchPatientList()
                setShowcreateform(false)
                setCreatelodar(false)

            }
            else {
                console.log(apicall)
                notifications.show({
                    title: "",
                    message: apicall?.data?.message ? apicall?.data?.message:"Something Error!"  ,
                })
                setCreatelodar(false)
            }
        }
        else {
            const apidata = {
                name: value.name,
                email: value.email,
                password: value.password,
                mobile: value.mobile,
                patientId: patientDetail.patientid
            }
            const apicall = await patientFamilyService.createPatientfamilymember(apidata)
            console.log(apicall)
            if (apicall.status === 201) {
                setShowcreateform(false)
                setCreatelodar(false)
                notifications.show({
                    title: 'Create Patient Family',
                    message: 'Create Success!',
                })
                FetchPatientList()
                setShowcreateform(false)
                setCreatelodar(false)
            }
            else {
                console.log(apicall)
                notifications.show({
                    title: "",
                    message: apicall?.data?.message ? apicall?.data?.message:"Something Went Wrong!"  ,
                })
                setCreatelodar(false)
            }
        }
    }
    useEffect(() => {
        setBreadCampList([{ title: "Patient List", handleclick: inactiveclick }]);
        FetchPatientList()
    }, [])
    return (
        <AssignPatientfamilycontext.Provider value={{createlodar, deletePatientFamilyUser, setShowcreateform, familyDetail,updateform, form, fetchFamilydetailbyPatientId, createPatientFamily, patientDetail, showcreateForm, patientList, loading }}>
            {children}
        </AssignPatientfamilycontext.Provider>
    )
}
export default AssignPatientFamilyprovider;
