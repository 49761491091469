// import Cookies from "universal-cookie";
import Axios from "../helper/coordinatorAxiosHelper";
import { BASEURL } from "../constants/constants";

const baseUrl = BASEURL;
// const cookies = new Cookies();

const axios = new Axios();

class CoordinatorUsersService {
  getDoctorList() {
    const option = {
      url: `${baseUrl}/provider`,
    };

    return axios.get(option);
  }

  getPatientList(id) {
    const option = {
      url: `${baseUrl}/getpatientBydoctor/${id}`,
    };
    return axios.get(option);
  }

  createPatient(data) {
    const option = {
      url: `${baseUrl}/patient`,
      data: {
        values: {
          name: data.name,
          email: data.email,
          password: data.password,
          mobileNumber: data.mobile,
          doctorId: data.doctorId,
        },
      },
    };

    return axios.post(option);
  }
}

const coordinatorUsersService = new CoordinatorUsersService();
export default coordinatorUsersService;
