import React, { useState, useEffect } from 'react';


import { Title, Text, Button,Loader,Stack, Container, Group, Center } from '@mantine/core';
import {IconHourglassEmpty} from '@tabler/icons-react';
import classes from './NotFoundTitle.module.scss';

 function PatientNotFound() {
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1900); // 1900 milliseconds (1.9 seconds)

  //   return () => clearTimeout(timer);
  // }, []);
  return (
    <>
    <Container className={classes.root}>
        {/* {isLoading ? (
                <Center display='flex'>

          <Loader/>
          </Center>

        ) : (
          <> */}
          <Center display='flex' >
            <Title className={classes.title}>No Patient is assigned to this Doctor.</Title>
          </Center>
           
          {/* </> */}
        {/* )} */}
 
    </Container>
  </>
  
  );
}

export default PatientNotFound;