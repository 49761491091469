import {
  Box,
  Button,
  Container,
  Grid,
  Rating,
  Title,
  rem, Flex, Group, Image
} from "@mantine/core";
import PainLogo from "../../constants/Logo/PainLogo.png";

import {
  IconMoodCrazyHappy,
  //   IconMoodCryFilled,
  IconMoodEmptyFilled,
  IconMoodHappyFilled,
  IconMoodSad,
  IconMoodSmileFilled,
  IconIconsOff,
} from "@tabler/icons-react";
import { StorePainData } from "../../services/pier";
import { useState } from "react";
import { questions } from "./PainLabel";
import painimage from '../../assets/painimage.png';
import "./Pain.scss";

// import emojiImage from "../../assets/slider.png"
import { useNavigate } from "react-router-dom";
const PainBody = () => {
  const [que, setque] = useState(questions)
  const[loading,setLoading] = useState(false)
  const [answers, setAnswers] = useState({
    p1: "0",
    p2: "0",
    p3: "0",
    p4: "0",
    p5: "0",
    p6: "0",
    p7: "0",
    p8: "0",
    p9: "0",
    p10: "0",
    p11: "0",
    p12: "0",
  });



  const navigate = useNavigate();
  const size = '2em'
  const handleEmojiClick = (questionKey, value) => {
    setAnswers((prevState) => ({ ...prevState, [questionKey]: value }));
  };

  const handleClick = async () => {
    setLoading(true)
    console.log(answers);
    try {
    const response =  await StorePainData(answers);
    if(response.status == 200)
      setLoading(false)
      navigate('/patientDashboard/view-exercise');
    } catch {
      console.log("error");
    }
  };

  const doSomething = (value, ans, ansvalue, color) => {
    if (ans === 0) {
      if (ans <= ansvalue) {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = color
      }
      else {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = ''

      }
    }
    else if (ans === 1) {
      if (ans <= ansvalue) {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = color
      }
      else {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = ''

      }

    }
    else if (ans === 2) {
      if (ans <= ansvalue) {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = color
      }
      else {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = ''

      }

    }
    else if (ans === 3) {
      if (ans <= ansvalue) {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = color
      }
      else {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = ''

      }

    }
    else if (ans === 4) {
      if (ans <= ansvalue) {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = color
      }
      else {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = ''
      }

    }
    else if (ans === 5) {
      if (ans <= ansvalue) {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = color
      }
      else {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = ''

      }
    }
    else if (ans === 6) {
      if (ans <= ansvalue) {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = color
      }
      else {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = ''
      }

    }
    else if (ans === 7) {
      if (ans <= ansvalue) {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = color
      }
      else {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = ''
      }

    }
    else if (ans === 8) {
      if (ans <= ansvalue) {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = color
      }
      else {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = ''
      }

    }
    else if (ans === 9) {
      if (ans <= ansvalue) {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = color
      }
      else {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = ''
      }

    }
    else if (ans === 10) {
      if (ans <= ansvalue) {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = color
      }
      else {
        document.getElementById(`${value}_${ans}`).style.backgroundColor = ''
      }


    }
    // if (ans === 0) {
    //  if (ans <= ansvalue) {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = '#358745'
    //   }
    //   else {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = ''

    //   }
    // }
    // else if (ans === 1) {
    //    if (ans <= ansvalue) {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = '#489b41'
    //   }
    //   else {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = ''

    //   }

    // }
    // else if (ans === 2) {
    //   if (ans <= ansvalue) {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = '#91be32'
    //   }
    //   else {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = ''

    //   }

    // }
    // else if (ans === 3) {
    //   if (ans <= ansvalue) {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = '#b3ce2a'
    //   }
    //   else {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = ''

    //   }

    // }
    // else if (ans === 4) {
    //   if (ans <= ansvalue) {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = '#f9e319'
    //   }
    //   else {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = ''
    //   }

    // }
    // else if (ans === 5) {
    //   if (ans <= ansvalue) {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = '#f1bf13'
    //   }
    //   else {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = ''

    //   }
    // }
    // else if (ans === 6) {
    //   if (ans <= ansvalue) {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = '#f49809'
    //   }
    //   else {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = ''
    //   }

    // }
    // else if (ans === 7) {
    //   if (ans <= ansvalue) {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = '#f15f03'
    //   }
    //   else {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = ''
    //   }

    // }
    // else if (ans === 8) {
    //   if (ans <= ansvalue) {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = '#f27100'
    //   }
    //   else {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = ''
    //   }

    // }
    // else if (ans === 9) {
    //   if (ans <= ansvalue) {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = '#da0700'
    //   }
    //   else {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = ''
    //   }

    // }
    // else if (ans === 10) {
    //   if (ans <= ansvalue) {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = '#cf0002'
    //   }
    //   else {
    //     document.getElementById(`${value}_${ans}`).style.backgroundColor = ''
    //   }


    // }
    // document.getElementById(`${value}_${ans}`).style.backgroundColor  = color

  }
  const removeclassfunction = (value, ans) => {
    for (let i = 0; i <= 11; i++) {
      const samp = i < ans
      console.log(samp)
      if (samp === true) {
      }
      else if (samp === false) {
        console.log("gsyyhs")
        document.getElementById(`${value}_${ans}`).style.backgroundColor = ''
      }
      // doSomething(value, Number(i), Number(ans));
    }
  }
  const handlick = (value, ans, color) => {
    console.log(value, ans, color)
    for (let i = 0; i <= 11; i++) {
      doSomething(value, Number(i), Number(ans), color);
    }
    // removeclassfunction(value, Number(ans))
    setAnswers({
      ...answers,
      [value]: ans,
    });
  }
  const backgroundreturnoflabel = (index, color) => {
    // if(answers[`p${index}`]==="0"){
    //   return '#a1ddff'
    // }
    // else if(answers[`p${index}`]==="1"){
    //       return  '#b3f274'
    // }
    // console.log(document.getElementsByClassName('btn'))
    // document.getElementsByClassName('btn').style.backgroundColor = "red"

    const dhyyd = 10 <= 18
    // console.log(dhyyd)
    // const  bnah=Number(answers[`p${index+1}`])>=Number(`${index}`)
    // console.log(answers[`p${index+1}`],Number(`${index}`),Number(answers[`p${index+1}`])<Number(`${index}`))
    // if(bnah===true){
    //     return color
    // }
    // else{
    //   return ''
    // }
  }
  return (
    <div>
      <Container fluid w={{ xs: "97%", sm: "97%", lg: "100%" }}>
        <Grid>
          <Grid.Col mt={10}>
          <Group justify="space-between">
          <Title c={"#ffff"}>Pain:</Title>
            <Group justify="right">
              <Button
                 variant="gradient"
                 gradient={{ from: 'cyan', to: 'indigo', deg: 90 }}
                onClick={() => navigate("/patientDashboard/view-exercise")}
              >
                Skip
              </Button>
              <Button  variant="gradient" loading={loading}
      gradient={{ from: 'cyan', to: 'indigo', deg: 90 }} onClick={() => handleClick()}>
                Update
              </Button>
            </Group>
          </Group>
          </Grid.Col>
          <Grid.Col
            span={{ xs: "12", sm: "12", md: "12", lg: "12" }}
            order={{ xs: "1", sm: "1", lg: "1" }}
          >
            {/* <Title c={"#ffff"}>Pain:</Title> */}
            <Box py="md">
              {Object.entries(que).map(([key, value], indextqt) => {
                return (
                  <Box mb={'4%'} className="painlabelquestion" c={'#ffff'} key={key} py="md">
                    <Flex mb={'10px'} pt={'10px'} mt={'12px'} className="question_heading" span={{ xs: '12', sm: "12", lg: "6" }} ><Title order={5}>{value[0].title}</Title></Flex>
                    <Group gap='0' style={{ display: 'flex', justifyContent: 'space-between' }}>

                      <div style={{ borderTop: '3px solid #358745' }} class={`button question${key}`}>
                        <label onClick={() => handlick(key, "0", "#358745")} id={`p${indextqt + 1}_0`} style={{ borderLeft: '3px solid #358745', borderRight: '1.5px solid #358745' }} class="hdydhh btn btn-default" for={`question_no_${key}1`}>
                          <div>0</div>
                          <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 512 512">
                            <path fill="#358745" d="M414.39 97.61A224 224 0 1 0 97.61 414.39A224 224 0 1 0 414.39 97.61M328 208a24 24 0 1 1-24 24a23.94 23.94 0 0 1 24-24m-144 0a24 24 0 1 1-24 24a23.94 23.94 0 0 1 24-24m72.05 176c-45.42 0-83.75-29.49-95.72-69.83C159.29 310.65 158 304 158 304h196s-1.31 6.69-2.33 10.17c-11.78 40.36-50.2 69.83-95.62 69.83" />
                          </svg>
                        </label>
                      </div>
                      <div style={{ borderTop: '3px solid #489b41' }} class={`button question${key}`}>
                        <label id={`p${indextqt + 1}_1`} onClick={() => handlick(key, "1", "#489b41")} style={{ borderLeft: '1.5px solid #489b41', borderRight: '1.5px solid #489b41' }} class="btn btn-default" for={`question_no_${key}1`}>
                          <div>1</div>
                        </label>
                      </div>
                      <div style={{ borderTop: '3px solid #c5f551' }} class={`button question${key}`}>
                        <label onClick={() => handlick(key, "2", '#91be32')} id={`p${indextqt + 1}_2`} style={{ borderLeft: '1.5px solid #91be32', borderRight: '1.5px solid #91be32' }} class="btn btn-default" for={`question_no_${key}1`}>
                          <div>2</div>
                          <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
                            <path fill="#91be32" d="M4 24C4 12.954 12.954 4 24 4s20 8.954 20 20s-8.954 20-20 20S4 35.046 4 24m13.5-2a2.5 2.5 0 1 0 0-5a2.5 2.5 0 0 0 0 5M33 19.5a2.5 2.5 0 1 0-5 0a2.5 2.5 0 0 0 5 0M18.452 34.681A11.718 11.718 0 0 0 24 36a11.718 11.718 0 0 0 9.816-5.1a1.249 1.249 0 1 0-2.13-1.307A9.212 9.212 0 0 1 24 33.5a9.22 9.22 0 0 1-7.687-3.907a1.248 1.248 0 1 0-2.13 1.307a11.718 11.718 0 0 0 4.269 3.781" />
                          </svg>
                        </label>
                      </div>
                      <div style={{ borderTop: '3px solid #b3ce2a' }} class={`button question${key}`}>
                        <label id={`p${indextqt + 1}_3`} onClick={() => handlick(key, "3", '#b3ce2a')} style={{ borderLeft: '1.5px solid #b3ce2a', borderRight: '1.5px solid #b3ce2a' }} class="btn btn-default" for={`question_no_${key}1`}>
                          <div>3</div>
                        </label>
                      </div>
                      <div style={{ borderTop: '3px solid #f9e319' }} class={`button question${key}`}>
                        <label onClick={() => handlick(key, "4", '#f9e319')} id={`p${indextqt + 1}_4`} style={{ borderLeft: '1.5px solid #f9e319', borderRight: '1.5px solid #f9e319', background: `${backgroundreturnoflabel(indextqt, '#f9e319')}` }} class="btn btn-default" for={`question_no_${key}1`}>
                          <div>4</div> <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
                            <defs>
                              <mask id="ipSEmotionUnhappy0">
                                <g fill="none" stroke-linejoin="round" stroke-width="4.15">
                                  <path fill="#fff" stroke="#fff" d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z" />
                                  <path stroke="#000" stroke-linecap="round" d="M31 18v1m-14-1v1m14 12s-2-4-7-4s-7 4-7 4" />
                                </g>
                              </mask>
                            </defs>
                            <path fill="#f9e319" d="M0 0h48v48H0z" mask="url(#ipSEmotionUnhappy0)" />
                          </svg>
                        </label>
                      </div>
                      <div style={{ borderTop: '3px solid #f1bf13' }} class={`button question${key}`}>
                        <label id={`p${indextqt + 1}_5`} onClick={() => handlick(key, "5", '#f1bf13')} style={{ borderLeft: '1.5px solid #f1bf13', borderRight: '1.5px solid #f1bf13' }} class="btn btn-default" for={`question_no_${key}1`}>
                          <div>5</div>
                        </label>
                      </div>
                      <div style={{ borderTop: '3px solid #f49809' }} class={`button question${key}`}>
                        <label onClick={() => handlick(key, "6", '#f49809')} id={`p${indextqt + 1}_6`} style={{ borderLeft: '1.5px solid #f49809', borderRight: '1.5px solid #f49809' }} class="btn btn-default" for={`question_no_${key}1`}>
                          <div>6</div> <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
                            <path fill="#f49809" d="M12 1.998c5.524 0 10.002 4.478 10.002 10.002c0 5.523-4.478 10-10.002 10c-5.524.001-10-4.477-10-10C1.999 6.476 6.476 1.998 12 1.998m0 11.5A5.984 5.984 0 0 0 7.712 15.3a.75.75 0 1 0 1.072 1.05A4.485 4.485 0 0 1 12 14.996c1.225 0 2.37.49 3.211 1.347a.75.75 0 1 0 1.07-1.052a5.984 5.984 0 0 0-4.28-1.795M9 8.75a1.25 1.25 0 1 0 0 2.499A1.25 1.25 0 0 0 9 8.75m6 0a1.25 1.25 0 1 0 0 2.499a1.25 1.25 0 0 0 0-2.499" />
                          </svg>
                        </label>
                      </div>
                      <div style={{ borderTop: '3px solid #f15f03' }} class={`button question${key}`}>
                        <label id={`p${indextqt + 1}_7`} onClick={() => handlick(key, "7", '#f15f03')} style={{ borderLeft: '1.5px solid #f15f03', borderRight: '1.5px solid #f15f03' }} class="btn btn-default" for={`question_no_${key}1`}>
                          <div>7</div>
                        </label>
                      </div>
                      <div style={{ borderTop: '3px solid #f27100' }} class={`button question${key}`}>
                        <label onClick={() => handlick(key, "8", '#f27100')} id={`p${indextqt + 1}_8`} style={{ borderLeft: '1.5px solid #f27100', borderRight: '1.5px solid #f27100' }} class="btn btn-default" for={`question_no_${key}1`}>
                          <div>8</div> <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
                            <path fill="#f27100" d="M5.14 17.57c0-1.07 1.18-3.07 2.43-4.76C8.82 14.5 10 16.5 10 17.57A2.43 2.43 0 0 1 7.57 20c-1.34 0-2.43-1.09-2.43-2.43M22 12a10 10 0 0 1-10 10c-.92 0-1.82-.14-2.67-.37A4.424 4.424 0 0 0 12 17.57c0-.45-.11-.97-.31-1.57H12c1.25 0 2.32.5 2.77 1.23l1.42-1.42C15.29 14.72 13.75 14 12 14c-.41 0-.81.04-1.19.12c-.43-.76-.96-1.59-1.62-2.49L8.71 11c.71-.13 1.29-.77 1.29-1.5C10 8.7 9.3 8 8.5 8S7 8.7 7 9.5c0 .19.04.37.11.54l-1.15 1.59C4.4 13.75 3.5 15.5 3.23 16.81C2.45 15.38 2 13.74 2 12A10 10 0 0 1 12 2a10 10 0 0 1 10 10m-5-2.5c0-.8-.7-1.5-1.5-1.5S14 8.7 14 9.5s.7 1.5 1.5 1.5s1.5-.7 1.5-1.5" />
                          </svg>
                        </label>
                      </div>
                      <div style={{ borderTop: '3px solid #da0700' }} class={`button question${key}`}>
                        <label id={`p${indextqt + 1}_9`} onClick={() => handlick(key, "9", '#da0700')} style={{ borderLeft: '1.5px solid #da0700', borderRight: '1.5px solid #da0700' }} class="btn btn-default" for={`question_no_${key}1`}>
                          <div>9</div>
                        </label>
                      </div>
                      <div
                        style={{ borderTop: '3px solid #cf0002' }}
                        class={`button question${key}`}>
                        <label id={`p${indextqt + 1}_10`} onClick={() => handlick(key, "10", '#cf0002')}
                          style={{ borderLeft: '1.5px solid #cf0002', borderRight: '3px solid #cf0002' }}
                          class="btn btn-default" for={`question_no_${key}1`}>
                          <div>10</div>
                          <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16">
                            <path fill="#cf0002" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M4.146 5.146a.5.5 0 0 1 .708 0l.646.647l.646-.647a.5.5 0 1 1 .708.708l-.647.646l.647.646a.5.5 0 1 1-.708.708L5.5 7.207l-.646.647a.5.5 0 1 1-.708-.708l.647-.646l-.647-.646a.5.5 0 0 1 0-.708m5 0a.5.5 0 0 1 .708 0l.646.647l.646-.647a.5.5 0 0 1 .708.708l-.647.646l.647.646a.5.5 0 0 1-.708.708l-.646-.647l-.646.647a.5.5 0 1 1-.708-.708l.647-.646l-.647-.646a.5.5 0 0 1 0-.708M8 13a2 2 0 1 1 0-4a2 2 0 0 1 0 4" />
                          </svg>
                        </label>
                      </div>
                    </Group>
                  </Box>
                )

              })}


            </Box>
            <br></br>
            <Box mt={"10px"} align="center">
              {/* <Button
                c={"black"}
                onClick={() => navigate("/patientDashboard/view-exercise")}
              >
                Skip
              </Button>
              &nbsp;&nbsp;
              <Button c={"black"} onClick={() => handleClick()}>
                Update
              </Button> */}
            </Box>
          </Grid.Col>


        </Grid>
      </Container>
    </div>
  );
};

export default PainBody;
