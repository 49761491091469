import React, { useContext, useEffect } from 'react';
import { TextInput, Checkbox,PasswordInput, Button, Group, Box } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BreadCampContext } from "../../../context/BreadCampContext";
import BreadCamp from "../../../resuable/BreadCamps";
import { AssignPatientfamilycontext } from './context/AssignpatientFamilycontext';
import { IconArrowLeft } from '@tabler/icons-react';

const AddPatientFamilyPage = () => {
    const { updateform, createlodar, form, setShowcreateform, familyDetail, fetchFamilydetailbyPatientId, createPatientFamily, patientDetail, patientList, loading, showcreateForm } = React.useContext(AssignPatientfamilycontext)

    useEffect(() => {
        form.setFieldValue('patientname', patientDetail?.name)
        updateform.setFieldValue('patientname', patientDetail?.name)

    }, [patientDetail])
    return (
        <>
            {showcreateForm === true ?
                <>
                    {familyDetail ?
                        <Box mt={25} maw={340} mx="auto">
                            <form onSubmit={updateform.onSubmit((values) => createPatientFamily(values))}>
                                <TextInput
                                    readOnly
                                    disabled
                                    mb={8}
                                    withAsterisk
                                    label="Patient Name"
                                    placeholder="Enter Patient Name"
                                    {...updateform.getInputProps('patientname')}
                                />
                                <TextInput
                                    mb={8}
                                    withAsterisk
                                    label="Representative Name"
                                    placeholder="Enter Name"
                                    {...updateform.getInputProps('name')}
                                />
                                <TextInput
                                    mb={8}
                                    withAsterisk
                                    label="Patient Representative Email"
                                    placeholder="Enter Email"
                                    {...updateform.getInputProps('email')}
                                />
                                <TextInput
                                    mb={8}
                                    withAsterisk
                                    label="Mobile No"
                                    placeholder="Enter Mobile Number"
                                    {...updateform.getInputProps('mobile')}
                                />
                                <PasswordInput
                                    mb={8}
                                    label="Update Password"
                                    placeholder="Enter Password"
                                    {...updateform.getInputProps('updatepassword')}
                                />

                                <Group mb={8} justify="space-between" mt="md">
                                    <Button w={'45%'} onClick={(() => { setShowcreateform(false) })}>  <IconArrowLeft style={{ marginRight: '3px', fontSize: '48px' }} stroke={5} />     Back Patient List</Button>
                                    {loading === true ? "" :
                                        <>
                                            {createlodar === true ?
                                                <Button  w={'50%'} loading loaderProps={{ type: 'dots' }}>
                                                    Loading button
                                                </Button> 
                                                :
                                                <Button  w={'45%'} type="submit">{familyDetail ? "Update" : "Submit"}</Button>
                                            }

                                        </>

                                    }
                                </Group>
                            </form>
                        </Box>
                        :
                        <Box mt={25} maw={340} mx="auto">
                            <form onSubmit={form.onSubmit((values) => createPatientFamily(values))}>
                                <TextInput
                                    readOnly
                                    disabled
                                    mb={8}
                                    withAsterisk
                                    label="Patient Name"
                                    placeholder="Enter Patient Name"
                                    {...form.getInputProps('patientname')}
                                />
                                <TextInput
                                    mb={8}
                                    withAsterisk
                                    label="Representative Name"
                                    placeholder="Enter Name"
                                    {...form.getInputProps('name')}
                                />
                                <TextInput
                                    mb={8}
                                    withAsterisk
                                    label="Patient Representative Email"
                                    placeholder="Enter Email"
                                    {...form.getInputProps('email')}
                                />
                                <TextInput
                                    mb={8}
                                    withAsterisk
                                    label="Mobile No"
                                    placeholder="Enter Mobile Number"
                                    {...form.getInputProps('mobile')}
                                />

                                <PasswordInput
                                    mb={8}
                                    withAsterisk
                                    label="Password"
                                    placeholder="Enter Password"
                                    {...form.getInputProps('password')}
                                />

                                <Group mb={8} justify="flex-end" mt="md">
                                    <Button onClick={(() => { setShowcreateform(false) })}>Back Patient List</Button>
                                    {loading === true ? 
                                      <Button  w={'50%'} loading loaderProps={{ type: 'dots' }}>
                                      Loading button
                                  </Button> 
                                    :
                                        <Button type="submit">{familyDetail ? "Update" : "Submit"}</Button>
                                    }
                                </Group>
                            </form>
                        </Box>
                    }
                </>
                : ""}
        </>
    )
}

export default AddPatientFamilyPage;