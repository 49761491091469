import {
  Center,
  Grid,
  Image,
  Group,
  Loader,
  Modal,
  Table,
  Tooltip,
  Button,
} from "@mantine/core";
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { IconEye } from "@tabler/icons-react";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DoctorDasboard } from "../../../../constants/constants";
import { BreadCampContext } from "../../../../context/BreadCampContext";
import BreadCamp from "../../../../resuable/BreadCamps";
import NotFoundComp from "../../../../resuable/NotFoundComp";
import dailyReportService from "../../../../services/dailyReportService";
import { navbarContext } from "../../context/DoctorDashboard";
import PatientRomView from "./PatientRomView";
import StatsGrid from "./RiskLevelShowCard";
import ViewPainGraph from "./viewPainGraph";
import ViewRomGraph from "./viewRomGraph";
import { Carousel } from "@mantine/carousel";
import axios from "axios";
import { BASEURL } from "../../../../constants/constants";
import TrackerService from '../../../../services/trackerService';
import { CircularProgressbar } from "react-circular-progressbar";
import styled from "styled-components";
function PatientDetailOverview(props) {
  const autoplay = useRef(Autoplay({ delay: 2000 }));

  const { setBreadCampList } = useContext(BreadCampContext);
  const params = useParams();
  const { setNavActive } = useContext(navbarContext);
  const [sortedData, setSortedData] = useState([]);
  const [list, setList] = useState([]);
  const [rows, setRows] = useState([]);
  const [patientListshow, setPatientListshow] = useState(true);
  const [createpatientshow, setCreatepatientshow] = useState(false);
  const navigate = useNavigate();
  const [noTransitionOpened, setNoTransitionOpened] = useState(false);
  const [graphOpen, setGraphOpen] = useState(false);
  const [graphData, setGraphData] = useState();
  const [graphResult, setGraphResult] = useState([]);

  const [graphPainData, setGrapPainhData] = useState();
  const [graphRiskData, setGrapRiskhData] = useState();

  const [graphPainOpen, setGraphPainopen] = useState();
  const [graphRiskOpen, setGraphRiskopen] = useState();
  const [imageAIlist, setImageAIlist] = useState([])
  const [romAIlist, setIRomAIlist] = useState([])

  const [PatientRomdata, setPatientRomdata] = useState();
  const [daysdata, setDaysdata] = useState([]);
  const [riskType, setRiskType] = useState();
  const [loading, setLoading] = useState(true);
  const [surgerydate, setSurgeryDate] = useState();
  const [breadcampitems, setBreadcampitems] = useState([
    { title: "OverView", href: "#" },
    { title: "Patient List", href: "#" },
  ]);
  const [imageAUrl, setImageAUrl] = useState();
  const [userData, setUserData] = useState([]);
  const trackerService = new TrackerService();
  const [emailid, setEmail] = useState("");
  const fetchsignedurl = async (data, set) => {
    const apicall = await dailyReportService.getSignedUrl({ imageurl: data });

    if (apicall.status === 200) {
      // imagestick=apicall.data.url
      if (apicall.data.url) {
        setImageAUrl(apicall.data.url);
        set.signurl = apicall.data.url;
        // return apicall.data.url;
      }

      // set.signurl = apicall.data.url;

      // return apicall.data.url
    } else {
      // return ''
    }
  };
  const FetchPatientRiskdata = async () => {
    console.log("alertriuhds")
    //getPatientRiskdata
    const apicall = await dailyReportService.getPatientRiskdata({ "patientId": params.patientid })
    if (apicall.status === 200) {
      console.log(apicall?.data?.data[0].riskreport)
      setRiskType(apicall?.data?.data[0].riskreport["Risk-category"]);
    }
    else {

    }
  }
  const FetchPatientList = async () => {
    const apicall = await dailyReportService.getDailyReportbyPatientId(
      params.patientid
    );

    if (apicall.statusCode === 200) {
      const setdata = apicall.data.result;
      const graphres = apicall.data.graphresult;
      if (graphres.length > 0) {
        setDaysdata(apicall.data.days);
        const filterkeydata = [];
        const filtergraphdata = [];
        setSurgeryDate(graphres[0].initialHealth[0].assignquestion[0].answer);
        console.log(graphres[0].initialHealth[0].assignquestion[0].answer);
        // setImageAIlist
        // const imageaialllist = graphres
        // let imageaiarr = []
        // imageaialllist.forEach((ex) => {
        //   if (ex?.patientimageai?.length > 0) {
        //     ex.patientimageai.forEach((ed) => {
        //       if (ed?.topAngleImage) {
        //         imageaiarr.push({ date: ed.createddate, image: ed.topAngleImage })
        //       }
        //     })
        //   }
        // })
        // setImageAIlist(imageaiarr)
        // console.log("imageaiarr")
        // console.log(imageaiarr)
        graphres.forEach((element) => {
          filtergraphdata.push({
            name: element.createddate,
            risklevel:
              element.risklevel === null ? 0 : element.risklevel.toFixed(2),
            mobile: element.createddate,
            id: element.patientId,
            romdata: element.patientrom,
            imageai: element.patientimageai,
            day: element.day,
            paindata: element.patientpain,
            daysname: element.daysname,
            thermaldata: element.patientthermaldata,
          });
        });
        if (filtergraphdata?.length > 0) {
          filtergraphdata?.forEach((edc) => {
            if (edc.imageai.length > 0) {
              edc.imageai.forEach((efv) => {
                fetchsignedurl(efv.topAngleImage, efv);
              });
            }
          });
        } else {
        }
        setdata.forEach((element) => {
          filterkeydata.push({
            name: element.createddate,
            risklevel:
              element.risklevel === null ? 0 : element.risklevel.toFixed(2),
            mobile: element.createddate,
            id: element.patientId,
            romdata: element.patientrom,
            imageai: element.patientimageai,
            day: element.day,
            paindata: element.patientpain,
            daysname: element.daysname,
            thermaldata: element.patientthermaldata,
          });
        });

        if (filterkeydata?.length > 0) {
          filterkeydata?.forEach((edc) => {
            if (edc.imageai.length > 0) {
              edc.imageai.forEach((efv) => {
                fetchsignedurl(efv.topAngleImage, efv);
              });
            }
          });
        } else {
        }
        setGraphResult(filtergraphdata);
        setList(filterkeydata);
        setSortedData(filterkeydata);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
      else {
        setLoading(false);
      }
    }
    else {
      setLoading(false);
    }
  }

  const handleTrackerClick = () => {
    const baseUrl =
      "https://tracker-demo-frontendv1-vxdxefdmiq-uc.a.run.app/trackerdata";
    //const urlWithParams = `${baseUrl}?email=${encodeURIComponent(emailid)}`;
    const currentUrl = window.location.href;
    console.log('currenturl',currentUrl)
        //const urlWithParams = `${baseUrl}?email=${encodeURIComponent(emailid)}`;
    const emailParam = encodeURIComponent(emailid);
    const referrerParam = encodeURIComponent(currentUrl);
    const urlWithParams = `${baseUrl}?email=${emailParam}&referrer=${referrerParam}`;
    window.location.href = urlWithParams;
  };

  const getemails = async () => {
    try {
      const url = window.location.href;
      const uid = url.substring(url.lastIndexOf("/") + 1);
      const response = await trackerService.getemailbyid(uid);
      console.log("REPONSEE", response.data.email);
      const tempemail = response.data.email;
      setEmail(tempemail)
      {/*
      if (tempemail === "swilson@trusshealth.ai" ||tempemail === "tmoore@trusshealth.ai" ||tempemail === "tk01_patient@trusshealth.ai") {
        setEmail("salil@trusshealth.ai");
      } else if(tempemail === "tk_patient@trusshealth.ai")
        setEmail("kads@trusshealth.ai")
      else {
        setEmail("ai@trusshealth.ai");
      }
    */ }
    } catch (error) {
      console.error("Error to get emails:", error);
    }
  };

  useEffect(() => {
    getemails();
    setBreadCampList([
      { title: "Dashboard", route: DoctorDasboard },
      { title: "Patient Data" },
    ]);
    setNavActive("Dashboard");
    let count = 0;
    if (count === 0) {
      FetchPatientRiskdata()
      FetchPatientList();

      count = 1;
    } else {
    }
  }, []);

  const dataObject = {
    key1: "value1",
    key2: "value2",
  };

  const ClickCreatePatient = () => {
    navigate("/doctorDashboard/add-patient");
  };
  const typereturnfunction = (index) => {
    // if (index === 0) {
    //   return "Risk Level";
    // } else
    if (index === 1) {
      return "Pain Level";
    } else if (index === 2) {
      return "Range Of Motion";
    } else if (index === 3) {
      return "ImageAI";
    } else if (index === 4) {
      return "Thermal";
    }
  };
  const fetchrisklevelvalue = (day) => {
    const filter = list.filter((ex) => {
      return ex.daysname === day;
    });
    //console.log(day, filter, list);
    return filter[0]?.risklevel ? filter[0].risklevel : "N/A";
  };
  const fetchRangeOfMotionlevelvalue = (day) => {
    const filter = list.filter((ex) => {
      return ex.daysname === day;
    });

    if (filter[0]?.romdata?.length > 0) {
      const rom = filter[0].romdata;
      const angledata = `${rom[rom.length - 1].maxrom?.toFixed(2)}`;
      return angledata;
    } else {
      return "N/A";
    }
    //  return  filter[0].risklevel ?filter[0].risklevel:"N/A"
  };
  const fetchImageAIimage = (day) => {
    const filter = list.filter((ex) => {
      return ex.daysname === day;
    });

    if (filter[0]?.imageai?.length > 0) {
      const imageurlse =
        filter[0]?.imageai[filter[0]?.imageai?.length - 1].signurl;

      return (
        <img
          data-testid="ImagAi-tag"
          style={{ borderRadius: "10px", padding: "1px" }}
          width="100%"
          height="100%"
          src={imageurlse}
          alt="imageai"
        />
      );
    } else {
      return "N/A";
    }
  };
  const fetchThermal = (day) => {
    const filter = list.filter((ex) => {
      return ex.daysname === day;
    });
    console.log("filter");
    console.log(filter[0].thermaldata);
    if (filter[0]?.thermaldata?.length > 0) {
      const therdatacurrent = filter[0]?.thermaldata;
      return (
        <>
          <Carousel
            // withIndicators width="100%" height="100%"
            // plugins={[autoplay.current]}
            // onMouseEnter={autoplay.current.stop}
            // onMouseLeave={autoplay.current.reset}
            mx="auto"
            my="md"
            // height={300}
            withIndicators
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
          >
            {therdatacurrent.map((ec, index) => {
              return (
                <>
                  {ec.camera === "ici" ? (
                    <Carousel.Slide>
                      <Showthermailimage image={ec.image} />
                    </Carousel.Slide>
                  ) : (
                    <>
                      <Carousel.Slide>
                        <Showthermailimage image={ec.overlay} />
                      </Carousel.Slide>
                      {/* <Carousel.Slide>
                        <Showthermailimage image={ec.segement} />
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <Showthermailimage image={ec.roi_img} />
                      </Carousel.Slide>
                      <Carousel.Slide>
                        <Showthermailimage image={ec.zone_roi} />
                      </Carousel.Slide> */}
                    </>
                  )}
                </>
              );
            })}
          </Carousel>
        </>
      );
    } else {
      return "N/A";
    }
  };

  const returndatavaluefunction = (type, day) => {
    if (type === "Risk Level" || type === "Pain Level") {
      if (day === "day1") {
        //console.log("Risk Level");
        return fetchrisklevelvalue("day1");
      } else if (day === "day2") {
        return fetchrisklevelvalue("day2");
      } else if (day === "day3") {
        return fetchrisklevelvalue("day3");
      } else if (day === "day4") {
        return fetchrisklevelvalue("day4");
      }
    } else if (type === "Range Of Motion") {
      if (day === "day1") {
        return fetchRangeOfMotionlevelvalue("day1");
      } else if (day === "day2") {
        return fetchRangeOfMotionlevelvalue("day2");
      } else if (day === "day3") {
        return fetchRangeOfMotionlevelvalue("day3");
      } else if (day === "day4") {
        return fetchRangeOfMotionlevelvalue("day4");
      }
    } else if (type === "ImageAI") {
      if (day === "day1") {
        return fetchImageAIimage("day1");
      } else if (day === "day2") {
        return fetchImageAIimage("day2");
      } else if (day === "day3") {
        return fetchImageAIimage("day3");
      } else if (day === "day4") {
        return fetchImageAIimage("day4");
      }
    } else if (type === "Thermal") {
      console.log(type, day);
      if (day === "day1") {
        return fetchThermal("day1");
      } else if (day === "day2") {
        return fetchThermal("day2");
      } else if (day === "day3") {
        return fetchThermal("day3");
      } else if (day === "day4") {
        return fetchThermal("day4");
      }
    }
  };
  const clickGraph = (index, item) => {
    if (index === 0) {
      setGraphRiskopen(true);
      setGrapRiskhData(list);
    } else if (index === 1) {
      setGraphPainopen(true);
      setGrapPainhData(list);
    } else if (index === 2) {
      setGraphOpen(true), setGraphData(list);
    } else if (index === 3) {
      return "ImageAI";
    }
  };

  const checkResponse = async () => {
    if (emailid === ''){
        return
    }
    console.log("hellooo",emailid)
    const checkResponses = await axios.post('https://tracker-demo-backendv1-vxdxefdmiq-uc.a.run.app/check', {
        uid: '1234',
        email: emailid,
    });
    if (checkResponses.status === 200) {
        console.log("hey i was done")
        // console.log("Response data", checkResponses.data);
        const currentDate = new Date().toISOString().split('T')[0];
        const currentDayData = checkResponses.data[currentDate];

        if (currentDayData) {
            const caloriesOut = currentDayData.activity_summary?.summary?.caloriesOut;
            const totalDistance = currentDayData.activity_summary?.summary?.distances.find(d => d.activity === 'total')?.distance;
            const steps = currentDayData.activity_summary?.summary?.steps;

            console.log(`Current day's calories out: ${caloriesOut}`);
            console.log(`Current day's total distance: ${totalDistance} km`);
            console.log(`Current day's steps: ${steps}`);

            const spo2MaxValue = currentDayData.spo2_data?.value?.max;
            const spo2MinValue = currentDayData.spo2_data?.value?.min;
            const restingHeartRate = currentDayData.activity_summary?.summary?.restingHeartRate;

            console.log(`Current day's SpO2 max value: ${spo2MaxValue}`);
            console.log(`Current day's resting heart rate: ${restingHeartRate}`);

            setUserData({
                caloriesOut,
                totalDistance,
                steps,
                spo2MaxValue,
                restingHeartRate,
                spo2MinValue,
            });
            console.log(userData)
        } else {
            console.log("No data available for the current day.");
        }
    }
};


  useEffect(() => {
    checkResponse();
  }, [emailid]);

  const ProgressBarContainer = styled.div`
    flex: 0 0 25%; // Assign flex-basis to 25%, no grow, no shrink. This controls the width.
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 2%;
    // background: rgba(255,255,255,0.5);
    background: rgba(1, 82, 106, 0.5);
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      padding-top: 100%; // This creates a square aspect ratio, maintaining the circle.
    }

    .circular-progressbar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `;
  const CenteredContent = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
  `;
  const BackgroundContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; // Centers children in the cross-axis
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0px;
    padding: 20px 5px;
    width: 90%;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between; // Adjust if needed
      width: 100%;
    }
  `;

  const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(40px, 2fr)
    ); // Adjust minmax for desired column width
    gap: 20px;
    width: 100%;

    @media (min-width: 470px) {
      // For larger screens, adjust as needed, e.g., three columns
      grid-template-columns: repeat(3, 1fr);
    }
  `;

  const SectionContainer = styled.div`
    display: flex;
    flex: ${(props) => props.flexSize || 1};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 10px;
    padding: 20px;
    width: 100%;

    @media (min-width: 768px) {
      // Use flexSize to control width if provided, otherwise auto
      width: ${(props) =>
      props.flexSize ? `calc(${props.flexSize} * 100%)` : "auto"};
    }
  `;

  const Heading = styled.h2`
    color: #fff;
    margin-bottom: 20px;
  `;

  const ProgressBars = ({
    distance,
    calories,
    steps,
    spo2MaxValue,
    restingHeartRate,
    spo2MinValue,
  }) => {
    const distancePercentage = (distance / 10) * 100;
    const caloriesPercentage = (calories / 2500) * 100;
    const stepsPercentage = (steps / 10000) * 100;
    const spo2Percentage = spo2MaxValue;
    const heartRatePercentage = ((restingHeartRate - 50) / (100 - 50)) * 100;
    const spo2MinPercentage = spo2MinValue;

    // Define custom styles for the CircularProgressbar
    const customStyles = {
      path: {
        stroke: `rgba(62, 152, 199, 0.85)`,
        strokeLinecap: "round",
        transition: "stroke-dashoffset 0.5s ease 0s",
      },
      trail: {
        stroke: "rgba(255,255,255,0.2)", // More visible translucent trail color
        strokeLinecap: "round", // Match strokeLinecap with path for consistency
      },
      text: {
        fill: "#fff",
        fontSize: "10px",
        textAnchor: "middle",
        dominantBaseline: "middle",
      },
      background: {
        fill: "transparent",
      },
    };

    const ResponsiveText = styled.p`
  color: white;
  text-align: center;
  width: 100%;
  margin: 0;
  font-size: 16px; // Default font size for larger screens

  @media (max-width: 480px) {
    font-size: 10px; // Smaller font size for screens narrower than 480px
  }
`;
    return (
      <CenteredContent>
        <BackgroundContainer>
          <SectionContainer>
          <GridContainer  >
                            <div>
                            <ResponsiveText>Distance</ResponsiveText>
                            <ProgressBarContainer>
                                <CircularProgressbar value={distancePercentage} text={`${distance} km`} styles={customStyles} />
                            </ProgressBarContainer>
                            </div>
                            
                            <div>
                               {/* <p style={{color: 'white',textAlign: 'center',width: '100%',margin: '0px 0' }} >Calories</p> */}
                               <ResponsiveText>Calories</ResponsiveText>
                            <ProgressBarContainer>
                                <CircularProgressbar value={caloriesPercentage} text={`${calories} cal`} styles={customStyles} />
                            </ProgressBarContainer>
                            </div>
                            <div>
                            <ResponsiveText>Steps</ResponsiveText>
                            <ProgressBarContainer>
                                <CircularProgressbar value={stepsPercentage} text={`${steps} steps`} styles={customStyles} />
                            </ProgressBarContainer>
                            </div>
                        </GridContainer>
                    </SectionContainer>

                    <SectionContainer  >
                      {/*  <Heading>Vitals</Heading> */}
                        <GridContainer>
                        <div>
                        <ResponsiveText>SpO2 Max</ResponsiveText>
                            <ProgressBarContainer>
                                <CircularProgressbar value={spo2Percentage} text={`${spo2Percentage}%`} styles={customStyles} />
                            </ProgressBarContainer>
                            </div>
                        <div>
                        <ResponsiveText>SpO2 Min</ResponsiveText>
                            <ProgressBarContainer>
                                <CircularProgressbar value={spo2MinPercentage} text={`${spo2MinValue}%`} styles={customStyles} />
                            </ProgressBarContainer>
                        </div>
                        <div>
                        <ResponsiveText>Heart Rate</ResponsiveText>
                            <ProgressBarContainer>
                                <CircularProgressbar value={heartRatePercentage} text={`${restingHeartRate} BPM`} styles={customStyles} />
                            </ProgressBarContainer>
                            </div>
                        </GridContainer>
                    </SectionContainer>
        </BackgroundContainer>
      </CenteredContent>
    );
  };
  const dayesfind = (date) => {
    const from = surgerydate;
    const to = date;
    return (
      Math.abs(
        moment(from, "YYYY-MM-DD")
          .startOf("day")
          .diff(moment(to, "YYYY-MM-DD").startOf("day"), "days")
      ) + 1
    );
  };
  return (
    <>
      <Grid>
        <Grid.Col display="flex" style={{ justifyContent: "space-between" }}>
          <Group style={{ justifyContent: "space-between" }}>
            <BreadCamp />
          </Group>
        </Grid.Col>
        {!loading ? (
          <Grid.Col display="flex" style={{ justifyContent: "end" }}>
            <Group>
              <StatsGrid riskType={riskType} surgerydate={surgerydate} />
            </Group>
          </Grid.Col>
        ) : (
          ""
        )}
        {!loading ? (
          <Grid.Col display="flex">
            <table
              className="detailcustomtable"
              style={{ width: "100%", borderTop: "1px solid #ffff" }}
            >
              <tr style={{ backgroundColor: "skyblue", color: "black" }}>
                <th
                  style={{
                    backgroundColor: "skyblue",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  {params.patientname}
                </th>
                {daysdata.map((el) => {
                  return (
                    <td
                      style={{
                        textTransform: "capitalize",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      {moment(el.createddate).format("MMMM Do YYYY")}
                      {/* {dayesfind(moment(el.createddate).format('MMMM Do YYYY'))} */}
                      {/* (({Math.abs(moment.duration( moment(surgerydate, "YYYY-MM-DD").diff(moment(el.createddate).format('YYYY-MM-DD'))).asDays())})) */}
                    </td>
                  );
                })}
                <th style={{ color: "black", textAlign: "center" }}>Graph</th>
              </tr>
              {["1", "2", "3", "4", "5"].map((itend, indext) => {
                return (
                  <tr key={indext}>
                    <th
                      data-testid={`table-header-${indext}`}
                      className="tableshhet_0"
                      style={{ width: "10%", textAlign: "center" }}
                    >
                      {typereturnfunction(indext)}
                    </th>
                    {daysdata.map((el, index) => {
                      return (
                        <>
                          <td
                            style={{ textAlign: "center", width: "16%" }}
                            key={index}
                            className={`tableshhet_${index + 1}`}
                          >
                            {returndatavaluefunction(
                              typereturnfunction(indext),
                              `day${index + 1}`
                            )}
                          </td>
                        </>
                      );
                    })}
                    {indext === 3 || indext === 4 ? (
                      <>
                        {indext === 3 ? ""
                          // <Carousel
                          //   mx="auto"
                          //   my="md"
                          //   withIndicators
                          //   plugins={[autoplay.current]}
                          //   onMouseEnter={autoplay.current.stop}
                          //   onMouseLeave={autoplay.current.reset}
                          // >
                          //   {imageAIlist.map((ec, index) => {
                          //     return (
                          //       <>

                          //         <>
                          //           <Carousel.Slide>
                          //             <img
                          //               data-testid="ImagAi-tag"
                          //               style={{ borderRadius: "10px", padding: "1px" }}
                          //               width="100%"
                          //               height="100%"
                          //               src={ec.image}
                          //               alt="imageai"
                          //             />
                          //           </Carousel.Slide>
                                   
                          //         </>

                          //       </>
                          //     );
                          //   })}
                          // </Carousel>
                          : ""}
                      </>
                    ) : (
                      <>
                        {indext === 2 ? (
                          <>
                            <td
                              data-testid="RomButtonGraph"
                              className="graph_row"
                              style={{
                                width: "30%",
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                clickGraph(indext);
                              }}
                            >
                              <div
                                style={{
                                  // background: "#ffff",
                                  background: "rgba(255, 255, 255, 0.6)",
                                  margin: "2%",
                                  padding: "2%",
                                  borderRadius: "10px",
                                }}
                              >
                                <ViewRomGraph graphData={graphResult} />
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            {indext !== 0 ? (
                              <td
                                className="graph_row"
                                style={{
                                  width: "30%",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  clickGraph(indext);
                                }}
                              >
                                <div
                                  style={{
                                    // background: "#ffff",
                                    background: "rgba(255, 255, 255, 0.6)",
                                    margin: "2%",
                                    padding: "2%",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <ViewPainGraph graphData={graphResult} />
                                </div>
                              </td>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>
                    )}
                  </tr>
                );
              })}
              <tr>
                {/* Tracker Data cell */}
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "medium",
                  }}
                >
                  Tracker Data
                </td>

                {/* Combine last 5 columns into one */}
                <td colSpan="5" style={{ textAlign: "center" }}>
                  <ProgressBars
                    distance={userData.totalDistance || 0}
                    calories={userData.caloriesOut || 0}
                    steps={userData.steps || 0}
                    spo2MaxValue={userData.spo2MaxValue || 0}
                    restingHeartRate={userData.restingHeartRate || 0}
                    spo2MinValue={userData.spo2MinValue || 0}
                  />

                  <Button
                    style={{
                      background: "linear-gradient(to right, #ff7e5f, #feb47b)",
                      color: "#fff",
                      border: "none",
                      width: "50%",
                    }}
                    onClick={handleTrackerClick}
                  >
                    View Details
                  </Button>
                </td>
              </tr>
            </table>
          </Grid.Col>
        ) : (
          <Grid.Col>
            <Center m={200}>
              <Loader style={{ justifyContent: "center" }} />
            </Center>
          </Grid.Col>
        )}
      </Grid>

      <Modal
        data-testid="RomGraphModal"
        size={"80%"}
        opened={noTransitionOpened}
        onClose={() => setNoTransitionOpened(false)}
        // title="Please consider this"
        transitionProps={{
          transition: "fade",
          duration: 600,
          timingFunction: "linear",
        }}
      >
        <PatientRomView PatientRomdata={PatientRomdata} />
      </Modal>
      <Modal
        data-testid="graph-modal"
        size={"80%"}
        opened={graphOpen}
        onClose={() => setGraphOpen(false)}
        // title="Please consider this"
        transitionProps={{
          transition: "fade",
          duration: 600,
          timingFunction: "linear",
        }}
      >
        <ViewRomGraph graphData={graphResult} />
      </Modal>
      <Modal
        size={"80%"}
        opened={graphPainOpen}
        onClose={() => setGraphPainopen(false)}
        // title="Please consider this"
        transitionProps={{
          transition: "fade",
          duration: 600,
          timingFunction: "linear",
        }}
      >
        <ViewPainGraph graphData={graphResult} />
      </Modal>
      <Modal
        size={"80%"}
        opened={graphRiskOpen}
        onClose={() => setGraphRiskopen(false)}
        // title="Please consider this"
        transitionProps={{
          transition: "fade",
          duration: 600,
          timingFunction: "linear",
        }}
      >
        <ViewPainGraph graphData={graphResult} />
      </Modal>
    </>
  );
}
export default PatientDetailOverview;
const Showthermailimage = (props) => {
  return (
    <>
      <Image
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "10px",
        }}
        src={props.image}
        fallbackSrc="https://placehold.co/1000x1000?text=Image N/A"
      />
    </>
  );
};
// 65fcd0e22f175b2ec4808dde

// $2b$10$NIbFbjoNYq8Hvi4SmjHcau9c4Xc9KpogR.xyuvJenEJghiMKG87Y6
