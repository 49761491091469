import { Notification, Box, Grid, Title, Image, Button, Flex, Loader, Text, Center } from "@mantine/core";
import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from '@mantine/form';
import { BreadCampContext } from "../../context/BreadCampContext";
import { navbarContext } from "../DoctorDashBoard/context/DoctorDashboard";
import BreadCamp from "../../resuable/BreadCamps";
import patientService from '../../services/patientService';
import { consumers } from "stream";


const PatientFormCollection = () => {
  const { setBreadCampList } = useContext(BreadCampContext);
  const [surgerysubtype, setSurgerysubtype] = useState('');
  const [usbStream, setUsbStream] = useState(null);
  const [bosonStream, setBosonStream] = useState(null);
  const [captureTypeIndex, setCaptureTypeIndex] = useState(0);
  const [isStreaming, setIsStreaming] = useState(true);
  const [currentCapture, setCurrentCapture] = useState('');
  const [error, setError] = useState(null);
  const usbSocketRef = useRef(null);
  const bosonSocketRef = useRef(null);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
  const [usbConnected, setUsbConnected] = useState(false);
  const [bosonConnected, setBosonConnected] = useState(false);

  const [captureData, setCaptureData] = useState([]);
  const [captureSequenceComplete, setCaptureSequenceComplete] = useState(false);
  const [readyToInitialize, setReadyToInitialize] = useState(false);
  const usbInitCount = useRef(0);
  const bosonInitCount = useRef(0);
  const [candownload , setcandownload] = useState(false);

  const captureSequence = surgerysubtype === "both knee"
    ? ['left_knee_incision_center', 'left_knee_left_side', 'left_knee_right_side','right_knee_incision_center', 'right_knee_left_side', 'right_knee_right_side']
    : ['knee_incision_center', 'knee_left_side', 'knee_right_side', 'good_knee'];

  const updateCurrentCapture = () => {
    setCurrentCapture(captureSequence[captureTypeIndex]);
  };

  const closeWebSockets = () => {
    setIsClosing(true);
    if (usbSocketRef.current && usbSocketRef.current.readyState !== WebSocket.CLOSED) {
      usbSocketRef.current.close();
    }
    if (bosonSocketRef.current && bosonSocketRef.current.readyState !== WebSocket.CLOSED) {
      bosonSocketRef.current.close();
    }
  };

  const initializeWebSocket = (type, url, messageHandler, retryCount = 0) => {
    console.log("INIT",type)
    if (isClosing) return; // Prevent reconnection if closing
    const maxRetries = 1;
    const socketRef = type === 'usb' ? usbSocketRef : bosonSocketRef;
    socketRef.current = new WebSocket(url);
    socketRef.current.binaryType = 'arraybuffer';
    socketRef.current.onopen = () => {
      console.log(`${type} WebSocket connection opened.`);
      socketRef.current.onmessage = messageHandler;
      setIsStreaming(true);
      setIsLoading(false); 

      if (type === 'usb') setUsbConnected(true);
      if (type === 'boson') setBosonConnected(true);
    };
    socketRef.current.onerror = () => {
    //  console.error(`Error connecting to ${type} camera. Attempt ${retryCount + 1}`);
      if (retryCount < maxRetries && !isClosing) {
        setTimeout(() => {
          initializeWebSocket(type, url, messageHandler, retryCount + 1);
        }, 1000);
      } else {
        console.error(`Failed to connect to ${type} camera after ${maxRetries} attempts.`);
        setIsLoading(false); 

        if (type === 'usb') {
          setError('USB camera not connected.');
          setUsbConnected(false);
        }
        if (type === 'boson') {
          setError('Boson camera not connected.');
          setBosonConnected(false);
        }
      }
    };
  };

  const handleMessageUsb = (event) => {
   // console.log("Received message from USB WebSocket:", event.data);
    if (typeof event.data === 'string') {
      try {
        const data = JSON.parse(event.data);
        console.log("Parsed data:", data.data.usb_image);
        if (data.action === 'stream' && data.data.usb_image) {
          const base64Data = `data:image/jpeg;base64,${data.data.usb_image}`;
          setUsbStream(base64Data);
        }
      } catch (e) {
        console.log("Non-JSON message received: ", event.data);
      }
    } else if (event.data instanceof ArrayBuffer) {
      const blob = new Blob([event.data], { type: 'image/jpeg' });
      const url = URL.createObjectURL(blob);
      setUsbStream(url);
    }
  };

  const handleMessageBoson = (event) => {
   // console.log("Received message from Boson WebSocket:", event.data);
    if (typeof event.data === 'string') {
      try {
        const data = JSON.parse(event.data);
      //  console.log("Parsed data:", data);
        if (data.action === 'stream' && data.data.boson_image) {
          const bosonImage = data.data.boson_image ? `data:image/jpeg;base64,${data.data.boson_image}` : null;
          setBosonStream(bosonImage);
        }
      } catch (e) {
        console.log("Non-JSON message received: ", event.data);
      }
    } else if (event.data instanceof ArrayBuffer) {
      const blob = new Blob([event.data], { type: 'image/jpeg' });
      const url = URL.createObjectURL(blob);
      setBosonStream(url);
    }
  };

  const storeCaptureData = (captureType, usbImage, bosonImage, bosonRadiometric) => {
    setCaptureData(prevData => [
      ...prevData,
      {
        captureType,
        usbImage: usbImage || "null",
        bosonImage: bosonImage || "null",
        bosonRadiometric: bosonRadiometric || "null"
      }
    ]);
  };

  const handleCapture = () => {
    setIsStreaming(false);
    if (usbSocketRef.current && usbSocketRef.current.readyState === WebSocket.OPEN) {
      usbSocketRef.current.send(JSON.stringify({ action: 'hold', userKey: 'user_key' }));
    }
    if (bosonSocketRef.current && bosonSocketRef.current.readyState === WebSocket.OPEN) {
      bosonSocketRef.current.send(JSON.stringify({ action: 'hold', userKey: 'user_key' }));
    }
  };
  const handleDone = () => {
    const currentCaptureType = captureSequence[captureTypeIndex];
    let usbDataReceived = false;
    let bosonDataReceived = false;
    let usbData = null;
    let bosonData = null;
    let bosonradio = null;

    const handleMessage = (event) => {
        try {
            const data = JSON.parse(event.data);
            if (data.action === 'capture') {
                if (data.data.usb_image) {
                    usbDataReceived = true;
                    usbData = data.data.usb_image;
                }
                if (data.data.boson_image && data.data.boson_radiometric) {
                    bosonDataReceived = true;
                    bosonData = data.data.boson_image;
                    bosonradio = data.data.boson_radiometric;
                }

                if (usbDataReceived && bosonDataReceived) {
                    storeCaptureData(currentCaptureType, usbData, bosonData, bosonradio);

                    if (usbSocketRef.current) {
                        usbSocketRef.current.onmessage = handleMessageUsb;
                    }
                    if (bosonSocketRef.current) {
                        bosonSocketRef.current.onmessage = handleMessageBoson;
                    }
                    setIsStreaming(false);  // Stop streaming after capturing data

                    setCaptureTypeIndex((prev) => {
                        const newIndex = prev + 1;
                        if (newIndex >= captureSequence.length) {
                            setCaptureSequenceComplete(true);
                            return 0;
                        } else {
                            updateCurrentCapture();
                            setTimeout(() => {
                                setIsStreaming(true);
                                if (usbSocketRef.current && usbSocketRef.current.readyState === WebSocket.OPEN) {
                                    usbSocketRef.current.send(JSON.stringify({ action: 'resume' }));
                                }
                                if (bosonSocketRef.current && bosonSocketRef.current.readyState === WebSocket.OPEN) {
                                    bosonSocketRef.current.send(JSON.stringify({ action: 'resume' }));
                                }
                            }, 1000);
                            return newIndex;
                        }
                    });
                }
            }
        } catch (e) {
            console.log("Non-JSON message received: ", event.data);
        }
    };

    if (usbSocketRef.current && usbSocketRef.current.readyState === WebSocket.OPEN) {
        usbSocketRef.current.onmessage = handleMessage;
        usbSocketRef.current.send(JSON.stringify({ action: 'capture', userKey: 'user_key', captureType: currentCaptureType }));
    }
    if (bosonSocketRef.current && bosonSocketRef.current.readyState === WebSocket.OPEN) {
        bosonSocketRef.current.onmessage = handleMessage;
        bosonSocketRef.current.send(JSON.stringify({ action: 'capture', userKey: 'user_key', captureType: currentCaptureType }));
    }
};
  const handleRetake = () => {
    setIsStreaming(true);
    if (usbSocketRef.current && usbSocketRef.current.readyState === WebSocket.OPEN) {
      usbSocketRef.current.send(JSON.stringify({ action: 'resume' }));
    }
    if (bosonSocketRef.current && bosonSocketRef.current.readyState === WebSocket.OPEN) {
      bosonSocketRef.current.send(JSON.stringify({ action: 'resume' }));
    }
  };

  const getInitHealthData = async () => {
    try {
      setSurgerysubtype("both knee");
      setReadyToInitialize(true);
      // const apicall = await patientService.getQuestionListbyPatientId(params.patientid);
      // if (apicall.statusCode === 200) {
      //   const data = apicall.data;
      //   if (data.surgerysubtype.length === 1) {
      //     setSurgerysubtype(data.surgerysubtype[0]);
      //   } else if (data.surgerysubtype.length === 2) {
      //     setSurgerysubtype("both knee");
      //   }
      //   setReadyToInitialize(true);
      // }
    } catch (error) {
      setError('Error fetching initial health data.');
    }
  };
  const handleSubmit = () => {
    // Send the collected data to the backend 
    console.log("Submitting Data:", captureData);
    closeWebSockets();
    setIsClosing(true);
    setcandownload(true);
    // submission logic here
  };
  const base64ToJpeg = (base64Data, fileName) => {
    const link = document.createElement('a');
    link.href = `data:image/jpeg;base64,${base64Data}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const base64ToCsv = (base64Data, fileName) => {
    const link = document.createElement('a');
    link.href = `data:text/csv;base64,${base64Data}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
  
  useEffect(() => {
    setIsClosing(false);
    getInitHealthData();
  }, []);
  
  useEffect(() => {
    console.log(usbInitCount,bosonInitCount)
    console.log(readyToInitialize)      // this is the reason for calling multiple times.
    if (readyToInitialize && usbInitCount.current === 0) {    //fix for initialising cameras only once. 
      initializeWebSocket('usb', 'ws://localhost:8765', handleMessageUsb);
      usbInitCount.current += 1;
    }
    if (readyToInitialize && bosonInitCount.current === 0) {
      initializeWebSocket('boson', 'ws://localhost:8766', handleMessageBoson);
      bosonInitCount.current += 1;
    }
  
    console.log(surgerysubtype);
    setBreadCampList([
      { title: "Patient List", route: "/datacollector/patientlist" },
      { title: "Patient Daily Workout Form" }
    ]);
    updateCurrentCapture();
  
    return () => {
      setIsClosing(true);
      closeWebSockets();
    };
  }, [readyToInitialize]);
  
  useEffect(() => {
    if (captureTypeIndex < captureSequence.length) {
      updateCurrentCapture();
    }
  }, [captureTypeIndex]);

  //DOWNLOAD TEST 
  useEffect(() => {
    if (candownload) {
        captureData.forEach((data, index) => {
            if (data.usbImage && data.usbImage !== "null") {
                base64ToJpeg(data.usbImage, `usb_image_${index + 1}.jpg`);
            }
            if (data.bosonImage && data.bosonImage !== "null") {
                base64ToJpeg(data.bosonImage, `boson_image_${index + 1}.jpg`);
            }
            if (data.bosonRadiometric && data.bosonRadiometric !== "null") {
                base64ToCsv(data.bosonRadiometric, `boson_radiometric_${index + 1}.csv`);
            }
        });
    }
}, [candownload]);


  return (
    <Box>
      <br></br>
      <Center><Title order={2}>Patient Form Collection</Title></Center>
      <br></br>
      {/* {error && <Notification color="red">{error}</Notification>} */}
      {!captureSequenceComplete && (
        <>
          <Box style={{
            padding: '10px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            borderRadius: '10px',
            marginBottom: '20px',
            textAlign: 'center'
          }}>
            <Text style={{ fontSize: '20px' }}>{`Capture Sequence: ${currentCapture}`}</Text>
          </Box>
          {isLoading ? (
            <Center><Loader size="xl" /></Center>
          ) : (
            <>
              <br></br>
              <Grid>
                <Grid.Col span={6}>
                  {usbConnected ? (
                    <img src={usbStream} alt="USB Camera Stream" style={{ width: '100%', height: 'auto' }} />
                  ) : (
                    <Text color="red">USB camera not connected.</Text>
                  )}
                </Grid.Col>
                <Grid.Col span={6}>
                  {bosonConnected ? (
                    <img src={bosonStream} alt="Boson Camera Stream" style={{ width: '100%', height: 'auto' }} />
                  ) : (
                    <Text color="red">Boson camera not connected.</Text>
                  )}
                </Grid.Col>
              </Grid>
              {isStreaming ? (
                <Button onClick={handleCapture} style={{ display: 'block', margin: '0 auto', marginTop: '20px' }}>Capture</Button>
              ) : (
                <Flex justify="center" align="center" direction="column" style={{ marginTop: '20px' }}>
                  <Button onClick={handleDone} style={{ marginBottom: '10px' }}>Done</Button>
                  <Button onClick={handleRetake}>Retake</Button>
                </Flex>
              )}
            </>
          )}
        </>
      )}
      {captureSequenceComplete && (
        <Box>
          <Text style={{ fontSize: '20px', color: 'green', textAlign: 'center' }}>Data collection successful!</Text>
          <Center>
            <Button onClick={handleSubmit} style={{ marginTop: '20px' }}>Submit</Button>
          </Center>
        </Box>
      )}
    </Box>
  );
};

export default PatientFormCollection;