import { useEffect, useState } from "react";
import dailyReportService from "../../../services/dailyReportService";

const PatientDataFetch = () => {
    const [riskType, setRiskType] = useState();
    const [graphResult, setGraphResult] = useState([])
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true)
    const [saveonseSessiondata,setSaveoneSessionData]=useState({});
    const [surgerydate, setSurgeryDate] = useState();

    const fetchsignedurl = async (data, set) => {
        const apicall = await dailyReportService.getSignedUrl({ imageurl: data });
    
        if (apicall.status === 200) {
          // imagestick=apicall.data.url
          if (apicall.data.url) {
           
            return apicall.data.url;
          }
    
          // set.signurl = apicall.data.url;
    
          // return apicall.data.url
        } else {
          // return ''
        }
      };
      const getsurgerydate=(graphres)=>{
        if(graphres.length>0){
            if(graphres[0].initialHealth.length>0){
                const filterdata=graphres[0].initialHealth[0].assignquestion.filter((ex)=>{
                    return  ex.type==="calendar"
                })
                console.log(filterdata)
                if(filterdata.length>0){
                    setSurgeryDate(filterdata[0].answer)
                }
             
            }
        }

      }
      const FetchPatientRiskdata = async () => {
        console.log("alertriuhds")
        //getPatientRiskdata
        const apicall = await dailyReportService.getPatientRiskdata({ "patientId": "" })
        if (apicall.status === 200) {
          console.log(apicall?.data?.data[0].riskreport)
          setRiskType(apicall?.data?.data[0].riskreport["Risk-category"]);
        }
        else {
    
        }
      }
    const FetchPatientList = async () => {
        const id = 37348
        const apicall = await dailyReportService.getDailyReportbyPatientId(id);

        if (apicall.statusCode === 200) {
            const setdata = apicall.data.result;
            const graphres = apicall.data.graphresult
            //   setDaysdata(apicall.data.days);
            // setRiskType(apicall?.data?.risktype['Risk-category']);
            getsurgerydate(graphres)
            const filterkeydata = [];
            const filtergraphdata = [];
            graphres.forEach((element) => {
                filtergraphdata.push({
                    name: element.createddate,
                    risklevel:
                        element.risklevel === null ? 0 : element.risklevel.toFixed(2),
                    mobile: element.createddate,
                    id: element.patientId,
                    romdata: element.patientrom,
                    imageai: element.patientimageai,
                    day: element.day,
                    paindata: element.patientpain,
                    daysname: element.daysname,
                    patientthermaldata: element.patientthermaldata
                });
            });
            if (filtergraphdata?.length > 0) {
                filtergraphdata?.forEach((edc) => {
                    if (edc.imageai.length > 0) {
                        edc.imageai.forEach((efv) => {
                            fetchsignedurl(efv.topAngleImage, efv);
                        });
                    }
                });
            } else {
            }
            setdata.forEach((element) => {
                filterkeydata.push({
                    name: element.createddate,
                    risklevel:
                        element.risklevel === null ? 0 : element.risklevel.toFixed(2),
                    mobile: element.createddate,
                    id: element.patientId,
                    romdata: element.patientrom,
                    imageai: element.patientimageai,
                    day: element.day,
                    paindata: element.patientpain,
                    daysname: element.daysname,
                    patientthermaldata: element.patientthermaldata
                });
            });

            // if (filterkeydata?.length > 0) {
            //     filterkeydata?.forEach((edc) => {
            //         if (edc.imageai.length > 0) {
            //             edc.imageai.forEach((efv) => {
            //                 fetchsignedurl(efv.topAngleImage, efv);
            //             });
            //         }
            //     });
            // } else {
            // }
            // console.log(filtergraphdata)
            // const getthermallist=[]
            // filtergraphdata.forEach((ex,index)=>{
            //    if( ex.patientthermaldata.length>0){
            //         ex.patientthermaldata.forEach((eq,index)=>{
            //             if(eq?.thermaldata?.length>0){
            //                 const data={}
            //                 data[`bososn${index}`]=
            //                 getthermallist({})
            //             }
            //         })
            //    }
            // })
            // console.log(getthermallist)
            setGraphResult(filtergraphdata)
            setList(filterkeydata);
            const onedatadata=filtergraphdata[filtergraphdata.length-1]
            const painscore=onedatadata?.paindata?.length>0 ? onedatadata?.paindata[onedatadata?.paindata.length-1].score:"0"
            const rom=onedatadata.romdata.length>0 ? onedatadata?.romdata[onedatadata?.romdata?.length-1].maxrom:"0"
            const risklevel=apicall?.data?.risktype['risk-score']
            const imageaidf=filtergraphdata[filtergraphdata.length-1].imageai
            const imageai=[imageaidf[imageaidf.length-1]]
            setSaveoneSessionData({painscore:painscore,rom:rom,risklevel:risklevel,imageai:imageai})
            setTimeout(() => {
                setLoading(false)
                // toggle()
            }, 2000)
            //   setSortedData(filterkeydata);
        }
    };
    useEffect(()=>{
        let active=0
        if(active===0){
            FetchPatientRiskdata()
            FetchPatientList()
            active=1
        }
    },[])
    return { loading, list, graphResult, riskType,saveonseSessiondata,surgerydate }
}

export default PatientDataFetch;