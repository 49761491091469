import { Box, Button, Flex, Space, Table,Loader } from "@mantine/core";
import propTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import {IconCirclePlus} from '@tabler/icons-react';
import { BreadCampContext } from "../../../context/BreadCampContext";
import { routeCoordinatorDoctorTab } from "../../../constants/constants";
import BreadCamp from "../../../resuable/BreadCamps";
import { navbarContext } from "../Context/CoordinatorDashboard";
import coordinatorUsersService from "../../../services/CoordinatorUsersService";
import { useNavigate, useParams } from "react-router-dom";
import NotFoundComp from "../../../resuable/NotFoundComp";
import PatientNotFound from "../../../resuable/PatientNotFound";
const PatientListOverview = () => {
  const tableDataHeader = ["Sr No.", "Patient Name", "Email","Mobile Number"];
  const { setBreadCampList } = useContext(BreadCampContext);
  const { setNavActive } = useContext(navbarContext);
  const [patientList, setPatientList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const params = useParams();
  console.log(params);
  const { doctorId } = params;

  console.log("doctorId ", doctorId);

  const getPatientList = async () => {
    try {
      const { data } = await coordinatorUsersService.getPatientList(doctorId);
      setPatientList(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching patient list:", error);
    }
  };

  useEffect(() => {
    console.log("Testing");
    getPatientList();
  }, [doctorId]);

  useEffect(() => {
    setBreadCampList([
      {
        title: "Doctor",
        route: routeCoordinatorDoctorTab,
      },
      { title: "Patient List" },
    ]);
    setNavActive("Doctor");
  }, [setBreadCampList]);

  const rows = patientList.map((element, idx) => (
    <Table.Tr key={idx}>
      <Table.Td>{idx + 1}</Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{element.email}</Table.Td>
      <Table.Td>{element.mobile}</Table.Td>

      {/* <Table.Td>{element.severity ?? "High"}</Table.Td> */}
    </Table.Tr>
  ));

  const handleClick = () => {
    navigate(`/CoordinatorDashboard/add-patient/${doctorId}`);
  };
  const ClickCreatePatient= () => {
    navigate(`/CoordinatorDashboard/add-patient-data-collector/${doctorId}`);
  }

  return (
    <>
      <Flex style={{ alignItems: "center", justifyContent: "space-between" }}>
        <div
          style={{
            paddingTop: "25px",
            paddingLeft: "7px",
            paddingBottom: "-10px",
          }}
        >
          <BreadCamp />
        </div>
        <div
          style={{
            paddingTop: "25px",
            paddingRight: "15px",
            paddingBottom: "-10px",
          }}
        >
          <Button mr={10} onClick={() => handleClick()}  fw={600}
                            leftSection={<IconCirclePlus size={20} />}
                            variant="default">
            Create New Patient
          </Button>

          <Button
              onClick={ClickCreatePatient}
              fw={600}
              leftSection={<IconCirclePlus size={20} />}
              variant="default"
            >
              Create  Patient For Data Collector
            </Button>
        </div>
      </Flex>

      <Box
      style={{
        height: "70vh",
        overflow: "auto",
        margin: "20px",
      }}
    >
      <Space h={"md"} />
      {loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <Loader />
          </div>
        ) : patientList.length === 0 ? (
          <PatientNotFound />
        ) : (
        <Table
          style={{ width: "100%" }}
          striped
          withColumnBorders
          stripedColor={"#00526A"}
          stickyHeader
          stickyHeaderOffset={-1}
        >
          <Table.Thead style={{ background: "black" }}>
            <Table.Tr>
              {tableDataHeader.map((items) => {
                return (
                  <Table.Th c={"white"} key={items}>
                    {items}
                  </Table.Th>
                );
              })}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody style={{ overflowY: "auto" }}>{rows}</Table.Tbody>
        </Table>
      )}
    </Box>
    </>
  );
};

export default PatientListOverview;
