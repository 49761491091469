
import React, { useState } from 'react';
import { Table, Text, TextInput, Button } from '@mantine/core';

const data = [
  { id: 1, name: 'John', age: 25 },
  { id: 2, name: 'Jane', age: 30 },
  { id: 3, name: 'Doe', age: 40 },
];

const TrackerConfig = () => {
  const [searchTerm, setSearchTerm] = useState('');
  
  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <TextInput
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        placeholder="Search by name"
      />
      <Table data={filteredData}>
        <Table.Head>
          <Table.Row>
            <Table.Cell>Id</Table.Cell>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell>Age</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {(rowData) => (
            <Table.Row key={rowData.id}>
              <Table.Cell>{rowData.id}</Table.Cell>
              <Table.Cell>{rowData.name}</Table.Cell>
              <Table.Cell>{rowData.age}</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  )
};

export default TrackerConfig;
