import Cookies from "universal-cookie";
import * as jose from "jose";
import Axios from "../helper/axiosHelper";
import { BASEURL } from "../constants/constants";

const cookies = new Cookies();
const axios = new Axios();
const baseUrl = BASEURL;

class LoginService {
  loginUser(username, password, userType) {
    console.log(username, password, userType)
    let option = {};
    if (userType == "doctor") {
      option = {
        url: `${baseUrl}/providerlogin`,
        data: { email: username, password },
      };
    } else if (userType == "coordinator") {
      option = {
        url: `${baseUrl}/clinicalcordinatorlogin`,
        data: { email: username, password },
      };
    } else if (userType == "patient") {
      option = {
        url: `${baseUrl}/login${userType}`,
        data: { email: username, password },
      };
    } else if (userType == "physiotherapist") {
      option = {
        url: `${baseUrl}/physiotherapistlogin`,
        data: { email: username, password },
      };
    
    }
    else if(userType=="patientfamily"){
      option = {
        url: `${baseUrl}/loginpatientfamily`,
        data: { email: username, password },
      };
      
    }
    else if(userType=="datacollector"){
      option = {
        url: `${baseUrl}/logindatacollector`,
        data: { email: username, password },
      };
    }
    else {
      option = {
        url: `${baseUrl}/login${userType}`,
        data: { email: username, password },
      };
    }

    console.log("checkin options ", option);

    return axios.post(option);
  }

  doLogin(token) {
    console.log("token ", token);
    this.token = token;
    cookies.set("truss_session", token, { path: "/" });
    const decodedToken = jose.decodeJwt(token);
    cookies.set("userFlag", decodedToken.userType, { path: "/" });
  }

  dologout(userType) {
    cookies.remove("truss_session", {
      path: "/",
      domain: "app.trusshealth.ai",
    });
    cookies.remove("userFlag", { path: "/", domain: "app.trusshealth.ai" });
    cookies.remove("avatar", { path: "/", domain: "app.trusshealth.ai" });
    cookies.remove("truss_session", { path: "/", domain: "localhost" });
    cookies.remove("userFlag", { path: "/", domain: "localhost" });
    cookies.remove("avatar", { path: "/", domain: "localhost" });
    cookies.remove(`${userType}`, { path: "/", domain: "localhost" });
    localStorage.removeItem("namefield");
    localStorage.removeItem(`${userType}name`);
  }

  setUserType(data , token) {

    cookies.set(`${data}`, token, { path: "/" });
  }

  getUserType() {
    const userType = cookies.get("userFlag");
    // console.log("userFlag", userType);
    return userType;
  }

  getparamsFromCookie(value) {
    this.value = value;
    const token = cookies.get("truss_session");
    if (!token) {
      return "";
    }
    const decodedToken = jose.decodeJwt(token);
    // decode(token, { complete: true });
    let data;
    if (decodedToken) {
      switch (value) {
        case "username":
          data = decodedToken.name;
          break;
        case "email":
          data = decodedToken.email;
          break;
        case "userId":
          data = decodedToken.userId;
          break;
        case "accountId":
          data = decodedToken.accountId;
          break;
        case "avatar":
          data = decodedToken.avatar;
          break;
        case "jwt_session":
          data = token;
          break;
        case "userType":
          data = decodedToken.userType;
          break;
        case "id":
          data = decodedToken.id;
          break;
        default:
          data = "";
      }
    }

    return data;
  }

  setAvatar(link) {
    cookies.set("avatar", link, { path: "/" });
  }

  getAvatar() {
    const avatar = cookies.get("avatar");

    return avatar;
  }

  getServerUptime() {
    const option = {
      url: `${baseUrl}/api/uptime`,
    };

    return axios.get(option);
  }
  getUserId() {
    const id = cookies.get("id");

    return id;
  }

  checkRoute() {
    const userType = cookies.get("userFlag");
    if (userType === "admin") {
      return "/admin";
    } else {
      return "/";
    }
  }
}
const loginService = new LoginService();
export default loginService;
