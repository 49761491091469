import { Container, Menu, LoadingOverlay, UnstyledButton, rem, Group, Image, Grid, Modal, Title, Paper, SimpleGrid, Text, Button, useSafeMantineTheme } from '@mantine/core';

import { Carousel } from '@mantine/carousel';
import { useContext, useEffect, useState } from "react";
import { DoctorDasboard } from "../../../constants/constants";
import { BreadCampContext } from "../../../context/BreadCampContext";
import dailyReportService from "../../../services/dailyReportService";
import PatientRomView from "./PatientRomView";
import StatsGrid from "./RiskLevelShowCard";
import ViewPainGraph from "./viewPainGraph";
import ViewRomGraph from "./viewRomGraph";
import moment from 'moment';
import { BASEURL } from '../../../constants/constants';
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import styled from 'styled-components';
import {CenteredContent ,Heading, BackgroundContainer ,SectionContainer,GridContainer,ProgressBarContainer } from '../../../utills/utils'
import PatientHeader from '../PatientHeader';
import PatientDataFetch from '../Hooks/patientDataFetch';
import LoaderPage from '../../../resuable/loder';
import TrackerService from '../../../services/trackerService';
import ThermalService from '../../../services/thermalService';
import { each, get } from 'lodash';
import { current } from '@reduxjs/toolkit';

function PatientDetailDashboardolds() {
    const { loading, list, riskType, graphResult, surgerydate } = PatientDataFetch()
    const { setBreadCampList } = useContext(BreadCampContext);
    const [noTransitionOpened, setNoTransitionOpened] = useState(false);
    const [graphOpen, setGraphOpen] = useState(false);
    const [graphPainOpen, setGraphPainopen] = useState();
    const [graphRiskOpen, setGraphRiskopen] = useState();
    const [userData, setUserData] = useState([]);
    const [emailid, setEmail] = useState('');
    const trackerService = new TrackerService();
    const clickGraph = (index, item) => {
        if (index === 0) {
            setGraphRiskopen(true);
            //   setGrapRiskhData(list);
        } else if (index === 1) {
            setGraphPainopen(true);
            //   setGrapPainhData(list);
        } else if (index === 2) {
            setGraphOpen(true);
            //   setGraphData(list);
        } else if (index === 3) {
            return "ImageAI";
        }
    };
    const handleTrackerClick = () => {
        const baseUrl = import.meta.env.VITE_REACT_APP_Tracker_URL;
        const currentUrl = window.location.href;
        const emailParam = encodeURIComponent(emailid);
        const referrerParam = encodeURIComponent(currentUrl);
        const urlWithParams = `${baseUrl}?email=${emailParam}&referrer=${referrerParam}`;
        window.location.href = urlWithParams;
    };

    const getemails = async () => {
        try {
            const response = await trackerService.getemail();
            console.log("REPONSEE", response.data.email);
            const tempemail = response.data.email;
            setEmail(tempemail)
            {/*
          if (tempemail === "swilson@trusshealth.ai" ||tempemail === "tmoore@trusshealth.ai" ||tempemail === "tk01_patient@trusshealth.ai") {
            setEmail("salil@trusshealth.ai");
          } else if(tempemail === "tk_patient@trusshealth.ai")
            setEmail("kads@trusshealth.ai")
          else {
            setEmail("ai@trusshealth.ai");
          }
        */  }
        } catch (error) {
            console.error("Error to get emails:", error);
        }
    }

    useEffect(() => {
        getemails();
        setBreadCampList([
            { title: "Dashboard", route: DoctorDasboard },
            { title: "Patient Data" },
        ]);
    }, []);


    const checkResponse = async () => {
        if (emailid === '') {
            return
        }
        console.log("hellooo", emailid)
        const checkResponses = await axios.post('https://tracker-demo-backendv1-vxdxefdmiq-uc.a.run.app/check', {
            uid: '1234',
            email: emailid,
        });
        if (checkResponses.status === 200) {
            console.log("hey i was done")
            // console.log("Response data", checkResponses.data);
            const currentDate = new Date().toISOString().split('T')[0];
            const currentDayData = checkResponses.data[currentDate];

            if (currentDayData) {
                const caloriesOut = currentDayData.activity_summary?.summary?.caloriesOut;
                const totalDistance = currentDayData.activity_summary?.summary?.distances.find(d => d.activity === 'total')?.distance;
                const steps = currentDayData.activity_summary?.summary?.steps;

                console.log(`Current day's calories out: ${caloriesOut}`);
                console.log(`Current day's total distance: ${totalDistance} km`);
                console.log(`Current day's steps: ${steps}`);

                const spo2MaxValue = currentDayData.spo2_data?.value?.max;
                const spo2MinValue = currentDayData.spo2_data?.value?.min;
                const restingHeartRate = currentDayData.activity_summary?.summary?.restingHeartRate;

                console.log(`Current day's SpO2 max value: ${spo2MaxValue}`);
                console.log(`Current day's resting heart rate: ${restingHeartRate}`);

                setUserData({
                    caloriesOut,
                    totalDistance,
                    steps,
                    spo2MaxValue,
                    restingHeartRate,
                    spo2MinValue,
                });
                console.log(userData)
            } else {
                console.log("No data available for the current day.");
            }
        }
    };

    useEffect(() => {
        checkResponse();
    }, [emailid]);


    const ProgressBars = ({ distance, calories, steps, spo2MaxValue, restingHeartRate, spo2MinValue }) => {
        const distancePercentage = (distance / 10) * 100;
        const caloriesPercentage = (calories / 2500) * 100;
        const stepsPercentage = (steps / 10000) * 100;
        const spo2Percentage = spo2MaxValue;
        const heartRatePercentage = (restingHeartRate - 50) / (100 - 50) * 100;
        const spo2MinPercentage = spo2MinValue

        // Define custom styles for the CircularProgressbar
        const customStyles = {
            path: {
                stroke: `rgba(62, 152, 199, 0.85)`,
                strokeLinecap: 'round',
                transition: 'stroke-dashoffset 0.5s ease 0s',
            },
            trail: {
                stroke: 'rgba(255,255,255,0.2)', // More visible translucent trail color
                strokeLinecap: 'round', // Match strokeLinecap with path for consistency
            },
            text: {
                fill: '#fff',
                fontSize: '10px',
                textAnchor: 'middle',
                dominantBaseline: 'middle',
            },
            background: {
                fill: 'transparent',
            },
        };

        return (
            <CenteredContent>
                <BackgroundContainer>
                    <SectionContainer >
                        {/* <Heading>Activities</Heading>   
                       <div style={{ width:'100%',height:'20%',top:'0px' ,display:'flex', flexDirection: 'row', justify: 'center', alignItems: 'center' }}>
                       <div className="progressbar-label1">{"distance"}</div>
                       <div className="progressbar-label2">{"Calories"}</div>
                       <div className="progressbar-label3">{"Steps"}</div>
                       </div>
                       */}

                        <GridContainer  >
                            <div>
                                <p style={{ color: 'white', textAlign: 'center', width: '100%', margin: '0px 0' }} >Distance</p>
                                <ProgressBarContainer>
                                    <CircularProgressbar value={distancePercentage} text={`${distance} km`} styles={customStyles} />
                                </ProgressBarContainer>
                            </div>

                            <div>
                                <p style={{ color: 'white', textAlign: 'center', width: '100%', margin: '0px 0' }} >Calories</p>
                                <ProgressBarContainer>
                                    <CircularProgressbar value={caloriesPercentage} text={`${calories} cal`} styles={customStyles} />
                                </ProgressBarContainer>
                            </div>
                            <div>
                                <p style={{ color: 'white', textAlign: 'center', width: '100%', margin: '0px 0' }} >Steps</p>
                                <ProgressBarContainer>
                                    <CircularProgressbar value={stepsPercentage} text={`${steps} steps`} styles={customStyles} />
                                </ProgressBarContainer>
                            </div>
                        </GridContainer>
                    </SectionContainer>

                    <SectionContainer  >
                        {/*  <Heading>Vitals</Heading> */}
                        <GridContainer>
                            <div>
                                <p style={{ color: 'white', textAlign: 'center', width: '100%', margin: '0px 0' }} >SpO2 Max</p>
                                <ProgressBarContainer>
                                    <CircularProgressbar value={spo2Percentage} text={`${spo2Percentage}%`} styles={customStyles} />
                                </ProgressBarContainer>
                            </div>
                            <div>
                                <p style={{ color: 'white', textAlign: 'center', width: '100%', margin: '0px 0' }} >SpO2 Min</p>
                                <ProgressBarContainer>
                                    <CircularProgressbar value={spo2MinPercentage} text={`${spo2MinValue}%`} styles={customStyles} />
                                </ProgressBarContainer>
                            </div>
                            <div>
                                <p style={{ color: 'white', textAlign: 'center', width: '100%', margin: '0px 0' }} >Heart Rate</p>
                                <ProgressBarContainer>
                                    <CircularProgressbar value={heartRatePercentage} text={`${restingHeartRate} BPM`} styles={customStyles} />
                                </ProgressBarContainer>
                            </div>
                        </GridContainer>
                    </SectionContainer>

                </BackgroundContainer>
            </CenteredContent>
        );
    };
    console.log(graphResult)
    return (
        <>
            <>

                <Grid
                    style={{ marginTop: '80px' }}
                >

                    {!loading ?
                        <>
                            <Grid.Col w={100}>
                                <Group justify='end'>
                                    <StatsGrid surgerydate={surgerydate} riskType={riskType} />
                                </Group>
                            </Grid.Col>

                            <Grid.Col>
                                <div>
                                    <SimpleGrid cols={{ base: 1, xs: 2, md: 3 }}>
                                        {/* <div style={{ display: 'block' }}>
                                            <Text c={'#ffff'}>Risk Level: </Text>
                                            <Paper withBorder p="md" radius="md" onClick={() => {
                                                clickGraph(0);
                                            }}>
                                                <ViewPainGraph graphData={graphResult} />
                                            </Paper>

                                        </div> */}
                                        <div style={{ display: 'block' }}>
                                        <Text  size='xl' fz={25} mb={5} c={'#B3F9FF'}>Pain: </Text>
                                            <Paper
                                                onClick={() => {
                                                    clickGraph(1);
                                                }}
                                                withBorder p="md" radius="md" mah={300}>
                                                <ViewPainGraph graphData={graphResult} />
                                            </Paper>

                                        </div>
                                        <div style={{ display: 'block' }}>
                                            <Text  c={'#B3F9FF'} fz={25} mb={5} >Range Of Motion: </Text>
                                            <Paper
                                                onClick={() => {
                                                    clickGraph(2);
                                                }}
                                                withBorder p="md" radius="md">
                                                <ViewRomGraph graphData={graphResult} />
                                            </Paper>

                                        </div>

                                    </SimpleGrid>
                                </div>
                            </Grid.Col>

                            <Grid.Col >
                                <Title  c={'#B3F9FF'} size='xl' fz={25} mb={5}>ImageAI: </Title>
                                <SimpleGrid cols={{ base: 1, xs: 2, md: 4 }}>
                                    {graphResult.map((ex, index) => {
                                        console.log("ex is " , ex.imageai);
                                        return (
                                            <>
                                                {ex?.imageai?.length > 0 ?
                                                    <>
                                                        <Carousel key={ex.imageai._id} withIndicators className='crousel_height_size_res' p={'2%'} style={{ position: 'relative', borderRadius: '10px', border: '2px solid #ffff' }}>
                                                            {ex?.imageai?.map((ec, index) => {
                                                                return (
                                                                    <>
                                                                        <Carousel.Slide key={ec._id} c={'#ffff'}>
                                                                            <Text style={{ position: 'absolute', top: '0px', left: '15px', color: '#B3F9FF' }}>{moment(ec.createdAt).format("MMM Do YY")}</Text>
                                                                            <ShowImage data={ec} />
                                                                        </Carousel.Slide>
                                                                    </>
                                                                )
                                                            })}
                                                        </Carousel>
                                                    </>
                                                    :<Paper bg={'#80B9AD'} className='crousel_height_size_res' shadow="xs" p="xl"  component="button" >
                                                    <Text order={3} >Knee Image are not available for this patient.</Text>
                                                   
                                                  </Paper>}


                                            </>
                                        )
                                    })}
                                </SimpleGrid>
                            </Grid.Col>
                            <Grid.Col>
                                <Title  c={'#B3F9FF'} size='xl' fz={25} mb={5}>Thermal: </Title>
                                <SimpleGrid cols={{ base: 1, xs: 2, md: 4 }}>
                                    {  graphResult[0]?.patientthermaldata.length> 0 ?  graphResult.map((ex, index) => {
                                        return (
                                            <>
                                                {ex?.patientthermaldata?.length > 0 ?
                                                    <>
                                                        <Carousel withIndicators className='crousel_height_size_res' p={'2%'} style={{ position: 'relative', borderRadius: '10px', border: '2px solid #ffff' }}>
                                                            {ex?.patientthermaldata?.map((ec, index) => {
                                                                return (
                                                                    <>
                                                                        {ec.camera === "ici" ?
                                                                            <Carousel.Slide key={index} c={'#ffff'}>
                                                                                <Text style={{ position: 'absolute', top: '0px', left: '15px', color: '#ffff' }}>{moment(ec.createdAt).format("MMM Do YY")}</Text>
                                                                                <Text style={{ position: 'absolute', top: '0px', right: '15px', color: '#228be6' }}>{ec.result}</Text>
                                                                                <Showthermailimage image={ec.image} />
                                                                            </Carousel.Slide>
                                                                            :
                                                                            <>
                                                                                {ec?.camera === "flir" ?
                                                                                    <>
                                                                                        <Carousel.Slide key={index} c={'#ffff'}>
                                                                                            <Text style={{ position: 'absolute', top: '0px', left: '15px', color: '#ffff' }}>{moment(ec.createdAt).format("MMM Do YY")}</Text>
                                                                                            <Text style={{ position: 'absolute', top: '0px', right: '15px', color: '#228be6' }}>{ec.result}</Text>
                                                                                            <Showthermailimage image={ec.overlay} />
                                                                                        </Carousel.Slide>

                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {ec.thermaldata.map((item, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    {/* {item.captureType==="left_knee_incision_center" ? */}
                                                                                                    <Carousel.Slide key={index} c={'#ffff'}>
                                                                                                        <Text style={{ position: 'absolute', top: '0px', left: '15px', color: '#ffff' }}>{moment(ec.createdAt).format("MMM Do YY")}</Text>
                                                                                                        <Text style={{ position: 'absolute', top: '0px', right: '15px', color: '#228be6',textTransform:"uppercase" }}>{item.captureType}</Text>
                                                                                                        <Showthermalimages file={item.bosonimage}  />
                                                                                                    </Carousel.Slide>
                                                                                                    {/* // :""} */}
                                                                                                </>
                                                                                            )
                                                                                        })}


                                                                                    </>
                                                                                }
                                                                            </>

                                                                        }
                                                                    </>
                                                                )
                                                            })}
                                                        </Carousel>
                                                    </>

                                                    :  <Paper className='crousel_height_size_res' bg={'#80B9AD'} shadow="xs" p="xl"  component="button" >
                                                    <Text  >Failed to Display Thermal Image.</Text>
                                                   
                                                  </Paper>}

                                            </>
                                        )
                                    })
                                    :  <Paper className='crousel_height_size_res' bg={'#80B9AD'} shadow="xs" p="xl"  component="button" >
                                    <Text   >Thermal Images are not available for this patient.</Text>
                                   
                                  </Paper>}
                                
                                </SimpleGrid>
                            </Grid.Col>
                            <Grid.Col>
                                <Title  c={'#B3F9FF'} size='xl' fz={25} mb={5}>Tracker: </Title>
                                {userData && (
                                    <ProgressBars
                                        distance={userData.totalDistance || 0}
                                        calories={userData.caloriesOut || 0}
                                        steps={userData.steps || 0}
                                        spo2MaxValue={userData.spo2MaxValue || 0}
                                        restingHeartRate={userData.restingHeartRate || 0}
                                        spo2MinValue={userData.spo2MinValue || 0}
                                    />
                                )}

                                <SimpleGrid cols={{ md: 1 }}>
                                    <Button style={{
                                        background: 'linear-gradient(to right, #ff7e5f, #feb47b)',
                                        color: '#fff',
                                        border: 'none',
                                        width: '50%',
                                        marginLeft: '25%'
                                    }} onClick={handleTrackerClick}>View Tracker Data </Button>
                                </SimpleGrid>
                            </Grid.Col>
                        </>
                        :
                        <div style={{ width: '100%', height: '100%' }}>
                            <LoaderPage />
                        </div>
                    }

                </Grid>
            </>
            <Modal
                size={"90%"}
                opened={noTransitionOpened}
                onClose={() => setNoTransitionOpened(false)}
                // title="Please consider this"
                transitionProps={{
                    transition: "fade",
                    duration: 600,
                    timingFunction: "linear",
                }}
            >
                <PatientRomView PatientRomdata={graphResult} />
            </Modal>
            <Modal
                size={"90%"}
                opened={graphOpen}
                onClose={() => setGraphOpen(false)}
                // title="Please consider this"
                transitionProps={{
                    transition: "fade",
                    duration: 600,
                    timingFunction: "linear",
                }}
            >
                <ViewRomGraph graphData={graphResult} />
            </Modal>
            <Modal
                size={"90%"}
                opened={graphPainOpen}
                onClose={() => setGraphPainopen(false)}
                // title="Please consider this"
                transitionProps={{
                    transition: "fade",
                    duration: 600,
                    timingFunction: "linear",
                }}
            >
                <ViewPainGraph graphData={graphResult} />
            </Modal>
            <Modal
                size={"90%"}
                opened={graphRiskOpen}
                onClose={() => setGraphRiskopen(false)}
                // title="Please consider this"
                transitionProps={{
                    transition: "fade",
                    duration: 600,
                    timingFunction: "linear",
                }}
            >
                <ViewPainGraph graphData={graphResult} />
            </Modal>
        </>

    );
}
export default PatientDetailDashboardolds;

const Showthermailimage = (props) => {
    return (
        <>
            <Image src={props.image}
            //  fallbackSrc="https://placehold.co/600x400?text=Image not found"
            />
        </>
    )
}

const ShowImage = (props) => {
    //console.log(props.data)
    const [imageUrl, setImageurl] = useState()
    const fetchsignedurl = async (data) => {
        if (data !== "") {
            const apicall = await dailyReportService.getSignedUrl({ imageurl: data });
            if (apicall.status === 200) {
                // imagestick=apicall.data.url
                if (apicall.data.url) {
                    setImageurl(apicall.data.url);

                }
                else {

                }
            } else {
            }
        }
        else {

        }

    };
   
    useEffect(() => {
        if (props.data.topAngleImage) {
            fetchsignedurl(props.data.topAngleImage)
        }
    }, [])
    return (
        <>
            <Image src={imageUrl}
                fallbackSrc="https://placehold.co/600x600?text=Image NaN"
            //  fallbackSrc="https://placehold.co/600x400?text=Image not found"
            />
        </>
    )
}
const Showthermalimages=(props)=> {
    const [image,setImage]=useState();
    const fetchsignedurl = async (data) => {
        if (data !== "") {
            const apicall = await dailyReportService.getThermalSignedUrl({ imageurl: data });
            if (apicall.status === 200) {
                // imagestick=apicall.data.url
                if (apicall.data.url) {
                    setImage(apicall.data.url);

                }
                else {

                }
            } else {
            }
        }
        else {

        }
    }
    useEffect(() => {
        if (props.file) {
            fetchsignedurl(props.file)
        }
    }, [])
    return (
        <>
            <Image src={image}
                fallbackSrc="https://placehold.co/600x600?text=Image NaN"
            //  fallbackSrc="https://placehold.co/600x400?text=Image not found"
            />
        </>
    )
}