import Axios from "../helper/axiosHelper";
import { BASEURL } from "../constants/constants";

const axios = new Axios();
const baseUrl = BASEURL;

class DataCollectorService {
    login() {
        const option = {
          url: `${baseUrl}/logindatacollector`,
        };
    
        return axios.get(option);
      }
      getlist() {
        console.log("data in admin call is");
        const option = {
          url: `${baseUrl}/getdatacollectorlist`,
        };
    
        return axios.get(option);
      }
      createdatacollector(data) {
        console.log("data in admin call is");
        const option = {
          url: `${baseUrl}/createdatacollector`,
          data,
        };
        return axios.post(option);
      }
      assignpatientfordatacollector(data) {
        const option = {
          url:`${baseUrl}/assignpatientfordatacollector`,
          data,
        }
        return axios.post(option);
      }
    //   deleteAdmin(id) {
    //     console.log("id is ",id);
    //     const option = {
    //       url: `${baseUrl}/admin/${id}`,
    //       data: { id },
    //     };
    
    //     return axios.delete(option);
    //   }
    //   getExercise() {
    //     console.log("get exercise callled");
    //     const option = {
    //       url: `${baseUrl}/exercise/global`,
    //     };
    
    //     return axios.get(option);
    //   }
}





const dataCollectorService = new DataCollectorService();
export default dataCollectorService;