import React from "react";

import { useEffect, useState } from "react";
import {
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  Skeleton,
  TextInput,
  rem,
  keys,
  Grid,
  Button,
  Loader,
} from "@mantine/core";
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
  IconPencil,
  IconTrash,
  IconEye,
} from "@tabler/icons-react";
import classes from "../../../styles/TableSort.module.css";
import PatientNotFound from "../../../resuable/PatientNotFound";
function Th({ children, reversed, sorted, onSort, head }) {
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <Table.Th className={`${classes.th} ${head}`}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}

function filterData(data, search) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
  );
}

function sortData(data, payload) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy].localeCompare(a[sortBy]);
      }

      return a[sortBy].localeCompare(b[sortBy]);
    }),
    payload.search
  );
}
const PatientTable = ({ list, rows, sortedData, setSortedData, loading }) => {
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(list, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(list, { sortBy, reversed: reverseSortDirection, search: value })
    );
  };

  return (
    <>
      {loading === true ? (
        <>
          <Center p={60}>
            <Loader />
          </Center>
        </>
      ) : (
        <ScrollArea>
          {sortedData.length > 0 ? (
            <>
              <TextInput
                placeholder="Search by any field"
                mb="md"
                leftSection={
                  <IconSearch
                    style={{ width: rem(16), height: rem(16) }}
                    stroke={1.5}
                  />
                }
                value={search}
                onChange={handleSearchChange}
                style={{ width: "30%" }}
              />
              <Table
                className="patientlist_table"
                horizontalSpacing="md"
                verticalSpacing="xs"
                miw={700}
                layout="fixed"
              >
                <Table.Tbody>
                  <Table.Tr>
                    <Th head="th1">Sr. No</Th>
                    <Th
                      head="th2"
                      sorted={sortBy === "name"}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting("name")}
                    >
                      Patient's Name
                    </Th>
                    <Th head="th3">Email Address</Th>
                    <Th head="th4">Contact Number</Th>
                    <Th head="th5">Patient Status</Th>
                    <Th head="th6">Action</Th>
                  </Table.Tr>

                  {rows}
                </Table.Tbody>
              </Table>
            </>
          ) : (
            <PatientNotFound />
          )}{" "}
        </ScrollArea>
      )}
    </>
  );
};

export default PatientTable;
