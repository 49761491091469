import React, { useContext, useEffect } from 'react';
import { Button, Checkbox, Group, TextInput, PasswordInput, Grid, Center } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BreadCampContext } from "../../../context/BreadCampContext";
import { Helmet } from "react-helmet-async";
import BreadCamp from '../../../resuable/BreadCamps';
import DataCollectorService from '../../../services/dataCollectorServices';

const CreateCollectorDataForm = () => {
    const { setBreadCampList } = useContext(BreadCampContext)
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            email: '', password: "", name: "", mobile: "",
            termsOfService: false,
        },

        validate: {
            //   email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });
    useEffect(() => {
        setBreadCampList([{ title: "Data Collector List", route: '/CoordinatorDashboard/datacollectorlist' }, { title: "Create Data Collector", route: "" }]);
    }, [])
    const createCollector = async (value) => {
        const apidata = {
            values: {
                name: value.name,
                email: value.email,
                mobileNumber: value.mobile,
                password: value.password,
            }
        }
        const apicall = await DataCollectorService.createdatacollector(apidata)
        console.log(apicall)
        if(apicall.status===201){
            
        }
    }
    return (
        <>

            <Helmet>
                Data Collector List
            </Helmet>
            <Grid>
                <Grid.Col>
                    <Group style={{ justifyContent: "space-between" }}>
                        <BreadCamp />

                    </Group>
                </Grid.Col>
                <Grid.Col>
                    <Center mt={"5%"} w={"100%"}>
                        <form style={{ width: "40%" }} onSubmit={form.onSubmit((values) => createCollector(values))}>
                            <TextInput
                                withAsterisk
                                label="Name"
                                placeholder="your@email.com"
                                {...form.getInputProps('name')}
                            />
                            <TextInput
                                withAsterisk
                                label="Email"
                                placeholder="your@email.com"
                                {...form.getInputProps('email')}
                            />
                            <TextInput
                                withAsterisk
                                label="Mobile Number"
                                placeholder="Mobile Number"
                                {...form.getInputProps('mobile')}
                            />
                            <PasswordInput
                                label="Password"
                                placeholder="Password"
                                {...form.getInputProps('password')}
                            />
                            <Group justify="flex-end" mt="md">
                                <Button w={"50%"} align="center" type="submit">Submit</Button>
                            </Group>
                        </form>
                    </Center>
                </Grid.Col>
            </Grid>


        </>
    )
}

export default CreateCollectorDataForm;