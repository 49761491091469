import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
// import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./styles.css";
import "@mantine/dates/styles.css";
import BreadCampProvider from "../src/context/BreadCampContext";
import PatientSteperProvider from "../src/context/PatientActionSteperContext.jsx";
import '@mantine/notifications/styles.css';

// console.log = () => {};
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HelmetProvider>
      {/* <BrowserRouter> */}
      <BreadCampProvider>
        <PatientSteperProvider>
          <App />
        </PatientSteperProvider>
      </BreadCampProvider>

      {/* </BrowserRouter> */}
    </HelmetProvider>
  </React.StrictMode>
);