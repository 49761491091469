// import {
//   Box,
//   Button,
//   Space,
//   Center,
//   Group,
//   Stepper,
//   Text,
// } from "@mantine/core";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import ImageCard from "./ImageCard";
// import HookSteper from "../../pages/PatientDashBoard/Hooks/HookSteper";
// import { Helmet } from "react-helmet-async";
// //uncomment adter adding API route in the pier.js file
// import { StoreImageAI } from "../../services/pier";

// const ImageBody = () => {
//   const [active, setActive] = useState(0);
//   const { postSurgery } = HookSteper();
//   // DO NOT DELETE :  the following code is needed when we have all the three screens
//   // const [submitText, setSubmitText] = useState("Next step");

//   //go back to the dashboard
//   const navigate = useNavigate();

//   //to store in react state to send to the API
//   const [dataImgdigital, setDataImgdigital] = useState("");
//   const [dataImgSideAngle, setDataImgSideAngle] = useState("");
//   const [dataImgthermal, setDataImgthermal] = useState("");
//   const [saveStatus, setSavestatus] = useState(false);
//   const [saveimageData, setSaveimageData] = useState([]);
//   const [displayText, setDisplayText] = useState(0);
//   const [resUnkown, setResUnkown] = useState(0);
//   const [resString, setResString] = useState(0);
//   const [resString1, setResString1] = useState(0);
//   console.log("resUnkown ", resUnkown);

//   //to switch to next steps for stepper
//   const nextStep = async () => {
//     setActive((current) => (current < 3 ? current + 1 : current));
//     navigate(postSurgery[1].route);
//     // DO NOT DELETE :  the following code is needed when we have all the three screens
//     // if (active == 1) {
//     //   setSubmitText("Submit");
//     // }
//     // if (active === 2) {
//     //   // navigate("/guidance");
//     //   const res = {
//     //     topAngleImage: dataImgdigital,
//     //     sideAngleImage: dataImgSideAngle,
//     //     thermalImage: dataImgthermal,
//     //   };
//     //   //function call for post request
//     //   const apicall = await StoreImageAI(res);
//     //   if (apicall.status === 201) {
//     //     setTimeout(() => {
//     //       setSavestatus(true);
//     //       setSaveimageData([
//     //         { imageurl: apicall?.data?.sideAngleImageurl },
//     //         { imageurl: apicall?.data?.thermalImageurl },
//     //         { imageurl: apicall?.data?.topAngleImageurl },
//     //       ]);
//     //     }, 2000);
//     //     //  navigate("/guidance")
//     //   } else {
//     //     // show error notification
//     //   }
//     // }

//     const res = {
//       topAngleImage: dataImgdigital,
//       sideAngleImage: dataImgSideAngle,
//       thermalImage: dataImgthermal,
//     };

//     setDisplayText(1);

//     //function call for post request
//     try {
//       // setSavestatus(true);
//       const apicall = await StoreImageAI(res);
//       console.log("checkin");
//       console.log("apicall ", apicall);
//       if (apicall.status === 201) {
//         console.log("test ");
//         setTimeout(() => {
//           // setSavestatus(true);
//           setSaveimageData([
//             { imageurl: apicall?.data?.sideAngleImageurl },
//             { imageurl: apicall?.data?.thermalImageurl },
//             { imageurl: apicall?.data?.topAngleImageurl },
//           ]);
//         }, 2000);
//         console.log("Image AI successful");
//         //  navigate("/guidance")
//         setResString(1);
//       } else {
//         console.log("API failed to send response");
//         // show error notification
//       }
//     } catch {
//       console.log("inside catch");
//       setSaveimageData([]);
//       setResString1(1);
//       setSaveimageData([{ imageurl1: "null" }]);
//       // setSavestatus(true);
//     }
//   };
//   const LabelText = [
//     {
//       title: "Angle - Top Angle Image",
//       desc: "Please keep your best to align your leg",
//     },
//     {
//       title: "Angle - Side Angle Image",
//       desc: "Please keep your best to align your leg within the blue line",
//     },
//     {
//       title: "Angle - Thermal Image",
//       desc: "Please keep your best to align your leg within the blue line",
//     },
//   ];
//   return (
//     <>
//       <Helmet>Image AI</Helmet>

//       <Center>
//         {saveStatus === true ? (
//           <Box align="center">
//             <Space h={"md"} />
//             <Text align="center" c={"#ffff"}>
//               Predicted Results
//             </Text>
//             <Space h={"md"} />
//             {resString1 && (
//               <Text c={"white"}>
//                 No detection available for this image or File format not
//                 supported
//               </Text>
//             )}
//             <Space h="md" />
//             <Grid w={"100%"}>
//               <Grid.Col span={{ md: 6, lg: 6, sm: 12 }}>
//                 <div>
//                   <Image src={dataImgdigital} radius="md" />
//                 </div>
//               </Grid.Col>
//               <Grid.Col span={{ md: 6, lg: 6, sm: 12 }}>
//                 {(resString == 1 || resString1 == 1) &&
//                 saveimageData.length > 0 ? (
//                   <>
//                     {saveimageData.map((el, index) => {
//                       return (
//                         <div key={index}>
//                           {el.imageurl ? (
//                             <Image src={el.imageurl} radius="md" />
//                           ) : (
//                             ""
//                           )}
//                         </div>
//                       );
//                     })}
//                   </>
//                 ) : (
//                   <Center
//                     h={"100%"}
//                     // style={{ minHeight: '200px', minWidth: '150px' }}
//                   >
//                     <Text c={"#ffff"}>Prediction In Progress</Text>
//                     <Space w={"lg"} />
//                     <Loader size={30} />
//                   </Center>
//                 )}
//               </Grid.Col>
//             </Grid>
//             {resUnkown === 0 && (
//               <Center>
//                 <Text c={"#ffff"}>No images uploaded </Text>
//               </Center>
//             )}
//             {/* </SimpleGrid> */}
//             <Space h="xl" />
//             <Button
//               w={"20%"}
//               c={"black"}
//               align="center"
//               onClick={() => {
//                 navigate("/patientDashboard/result");
//               }}
//             >
//               Next Steps
//             </Button>
//           </Box>
//         ) : (
//           <Box>
//             <Space h={"md"} />

//             <Group justify="right">
//               {displayText === 0 && (
//                 <Group>
//                   <Button
//                     variant="gradient"
//                     gradient={{ from: "cyan", to: "indigo", deg: 90 }}
//                     onClick={() => {
//                       navigate(postSurgery[1].route);
//                     }}
//                   >
//                     Skip
//                   </Button>
//                   <Button
//                     variant="gradient"
//                     gradient={{ from: "cyan", to: "indigo", deg: 90 }}
//                     onClick={nextStep}
//                   >
//                     Submit
//                   </Button>
//                 </Group>
//               )}
//             </Group>
//             <Center>
//               <Text c={"#ffff"}>
//                 Upload your photo & Follow the instructions below:
//               </Text>
//             </Center>
//             {/* <Space h={"md"} /> */}
//             {/* <Space h={"md"} /> */}
//             <Stepper active={active} onStepClick={setActive}>
//               <Stepper.Step
//                 c={"#ffff"}
//                 label="Complete the following and click on submit"
//               >
//                 <ImageCard
//                   LabelInfo={LabelText[active]}
//                   // setDataImg={set}
//                   dataImg={dataImgdigital}
//                   storeImgData={setDataImgdigital}
//                   resUpdate={setResUnkown}
//                 />
//               </Stepper.Step>
//               <Stepper.Completed></Stepper.Completed>
//             </Stepper>
//             {/* {displayText === 0 && (
//               <Group justify="center" mt="xl">
//                 <Button variant="gradient"
//                   gradient={{ from: 'cyan', to: 'indigo', deg: 90 }} onClick={nextStep}>
//                   Submit
//                 </Button>
//               </Group>
//             )} */}
//           </Box>
//         )}
//       </Center>
//     </>
//   );
// };

// export default ImageBody;

import { Box, Button, Center, Space, Text } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageAiUpload from "./ImageAiUpload";
import HookSteper from "../../pages/PatientDashBoard/Hooks/HookSteper";

const ImageBody = () => {
  const [captureFeed, setCaptureFeed] = useState(0);
  const [userChoice, setUserChoice] = useState("");
  const { postSurgery } = HookSteper();
  const navigate = useNavigate();

  const handleClick = (section) => {
    if (section === "Skip") {
      navigate(postSurgery[1].route);
    } else if (section === "Click Image") {
      setUserChoice("Click Image");
      setCaptureFeed(1);
    } else {
      setUserChoice("Upload");
      setCaptureFeed(1);
    }
  };

  return (
    <>
      {captureFeed === 0 ? (
        <>
          <Center style={{ flexDirection: "column" }}>
            {/* <Box style={{ flexDirection: "column", height: "70vh" }}> */}
            <div
              style={{
                height: "30%",
                display: "flex",

                // justifyContent: "center",
              }}
            >
              <Text size={"40px"} c={"white"} style={{ marginTop: "10vh" ,  }}>
                Please provide knee Image for AI Analysis
              </Text>
            </div>
            {/* </Box> */}

            <Box
              style={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                height: "40vh",
              }}
            >
              <Button c={"black"} onClick={() => handleClick("Click Image")}>
                Click Image
              </Button>
              <Space w="md" />
              <Button c={"black"} onClick={() => handleClick("Upload")}>
                Upload
              </Button>
              <Space w="md" />
              <Button
                c={"black"}
                onClick={() => {
                  navigate(postSurgery[1].route);
                }}
              >
                Skip
              </Button>
            </Box>
          </Center>
        </>
      ) : (
        <ImageAiUpload selectionFeed={userChoice} />
      )}
    </>
  );
};

export default ImageBody;
