import { useEffect, useState, useContext } from "react";
import {Helmet} from "react-helmet-async"
import {
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem,
  keys,
  Grid,
  Button,
  Tooltip,
} from "@mantine/core";
import {
  IconSelector,
  IconCirclePlus,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
  IconPencil,
  IconTrash,
  IconEye,
} from "@tabler/icons-react";
import PatientService from "../../../services/patientService";
import TableData from "./TableData";
import BreadCamp from "../../../resuable/BreadCamps";
import { Link, useNavigate } from "react-router-dom";
import { navbarContext } from "../Context/CoordinatorDashboard";
import { PatientDetailoverviewCoordinator } from "../../../constants/constants";
import dailyReportService from "../../../services/dailyReportService";
import moment from "moment";
import { BreadCampContext } from "../../../context/BreadCampContext";
import { CoordinatorDashboard } from "../../../constants/constants";

const OverViewCoordinator = (props) => {
  // console.log(props.value);
  const { setNavActive } = useContext(navbarContext);
  const [sortedData, setSortedData] = useState([]);
  const [list, setList] = useState([]);
  const [rows, setRows] = useState([]);
  const [patientListshow, setPatientListshow] = useState(true);
  const [createpatientshow, setCreatepatientshow] = useState(false);
  const navigate = useNavigate();
  const { setBreadCampList } = useContext(BreadCampContext);
  const [breadcampitems, setBreadcampitems] = useState([
    // { title: "DashBoard", href: "#" },
    { title: "Overview", href: "#" },
  ]);

  const FetchPatientList = async () => {
    const apiacll = await dailyReportService.getDailyReport();
    console.log(apiacll);
    if (apiacll.statusCode === 200) {
      console.log(apiacll);
      const setdata = apiacll.data.filter((elc) => {
        return elc.initialHealth.length > 0;
      });
      console.log(setdata);
      const filterkeydata = [];
      setdata.forEach((element) => {
        const date = element?.initialHealth[0].assignquestion.filter((ex) => {
          return ex.type === "calendar";
        });
        console.log(date);
        filterkeydata.push({
          dateofsurgery: date ? date[0].answer : "",
          risklevel:
            element.risklevel === null ? 0 : element.risklevel.toFixed(2),
          patientid: element._id,
        });
      });
      console.log(filterkeydata);
      setList(filterkeydata);
      setSortedData(filterkeydata);
      const rowdata = filterkeydata.map((row, index) => (
        <Table.Tr key={index}>
          <Table.Td>{index + 1}</Table.Td>
          <Table.Td>
            {moment(row.dateofsurgery, "YYYY-MM-DD").format("DD-MM-YY")}
          </Table.Td>
          <Table.Td>{row.risklevel} %</Table.Td>
          <Table.Td>
            <Group align="">
              <Tooltip label="view Patient Detail">
                <IconEye
                  onClick={() => {
                    navigate(
                      `${PatientDetailoverviewCoordinator}/rajukumar/12345`
                    );
                  }}
                  size={18}
                  color="#90EE90"
                />
              </Tooltip>
              
            </Group>
          </Table.Td>
        </Table.Tr>
      ));
      setRows(rowdata);
    }
  };

  useEffect(() => {
    setBreadCampList([{ title: "Dashboard", route: CoordinatorDashboard }]);
    setNavActive("Dashboard");
    FetchPatientList();
  }, []);

  const dataObject = {
    key1: "value1",
    key2: "value2",
  };

  useEffect(() => {
    const rowdata = sortedData.map((row, index) => (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}</Table.Td>
        <Table.Td>
          {moment(row.dateofsurgery, "YYYY-MM-DD").format("DD/MM/YY")}
        </Table.Td>
        <Table.Td>{row.risklevel} %</Table.Td>
        <Table.Td>
          <Group align="">
            <Tooltip label="view Patient Detail">
              <IconEye
                onClick={() => {
                  navigate(
                    `${PatientDetailoverviewCoordinator}/rajukumar/12345`
                  );
                }}
                size={18}
                color="#90EE90"
              />
            </Tooltip>
          </Group>
        </Table.Td>
      </Table.Tr>
    ));
    setRows(rowdata);
  }, [sortedData]);
  const ClickCreatePatient = () => {
    navigate("/doctorDashboard/add-patient");
    // setBreadcampitems([
    //   { title: "DashBoard", href: "#" },
    //   { title: "Patient List", href: "#" },
    //   { title: "Create Patient", href: "#" },
    // ]);
    // setCreatepatientshow(true);
    // setPatientListshow(false);
  };

  return (
    <>
     <Helmet>
        <title> Dashboard  | Truss Health </title>
      </Helmet>
    <Grid>
      <Grid.Col>
        <Group style={{ justifyContent: "space-between" }}>
          <BreadCamp data={breadcampitems} />
          {/* {createpatientshow === true ? (
                        ""
                    ) : (
                        <Button
                            onClick={ClickCreatePatient}
                            fw={600}
                            leftSection={<IconCirclePlus size={20} />}
                            variant="default"
                        >
                            Create Patient
                        </Button>
                    )} */}
        </Group>
      </Grid.Col>
      <Grid.Col>
        {patientListshow === true ? (
          <TableData
            list={list}
            rows={rows}
            sortedData={sortedData}
            setSortedData={setSortedData}
          />
        ) : (
          ""
        )}
        {/* {createpatientshow === true ? <AddEditPatient /> : ""} */}
      </Grid.Col>
    </Grid>
    </>
  );
};

export default OverViewCoordinator;
