import cx from "clsx";
import {
  HoverCard,
  Group,
  Button,
  UnstyledButton,
  Text,
  SimpleGrid,
  ThemeIcon,
  Anchor,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
  useMantineTheme,
  Avatar,
  Menu,
  Container,
  Grid, Image,
} from "@mantine/core";
import { MantineLogo } from "@mantinex/mantine-logo";
import { useDisclosure } from "@mantine/hooks";
import companyname from '../../../assets/company-name.png';
import {
  IconNotification,
  IconCode,
  IconBook,
  IconChartPie3,
  IconFingerprint,
  IconCoin,
  IconChevronDown,
  IconTrash,
  IconPlayerPause,
  IconLogout,
  IconSwitchHorizontal,
  IconSettings,
  IconMessage,
  IconStar,
  IconHeart,
  IconChevronUp, IconUserCircle
} from "@tabler/icons-react";
import classes from "./HeaderMegaMenu.module.css";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import NavBar from '../NavbarLinksGroup/NavBar';
import loginService from '../../../services/loginService';
import {DoctorLogin} from '../../../constants/constants';
// const mockdata = [
//   {
//     icon: IconCode,
//     title: "Open source",
//     description: "This Pokémon’s cry is very loud and distracting",
//   },
//   {
//     icon: IconCoin,
//     title: "Free for everyone",
//     description: "The fluid of Smeargle’s tail secretions changes",
//   },
//   {
//     icon: IconBook,
//     title: "Documentation",
//     description: "Yanma is capable of seeing 360 degrees without",
//   },
//   {
//     icon: IconFingerprint,
//     title: "Security",
//     description: "The shell’s rounded shape and the grooves on its.",
//   },
//   {
//     icon: IconChartPie3,
//     title: "Analytics",
//     description: "This Pokémon uses its flying ability to quickly chase",
//   },
//   {
//     icon: IconNotification,
//     title: "Notifications",
//     description: "Combusken battles with the intensely hot flames it spews",
//   },
// ];

const DoctorHeader = () => {
  const navigate = useNavigate()
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const theme = useMantineTheme();

  const [opened, { toggle }] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [name, setName] = useState(localStorage.getItem('namefield'))

  const user = {
    name: "Jane Spoonfighter",
    email: "janspoon@fighter.dev",
    image:
      "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-5.png",
  };
  const handleLogout = () => {
    loginService.dologout("doctor");
    navigate(DoctorLogin, { replace: true });
  }

  // const links = mockdata.map((item) => (
  //   <UnstyledButton className={classes.subLink} key={item.title}>
  //     <Group wrap="nowrap" align="flex-start">
  //       <ThemeIcon size={34} variant="default" radius="md">
  //         <item.icon
  //           style={{ width: rem(22), height: rem(22) }}
  //           color={theme.colors.blue[6]}
  //         />
  //       </ThemeIcon>
  //       <div>
  //         <Text size="sm" fw={500}>
  //           {item.title}
  //         </Text>
  //         <Text size="xs" c="dimmed">
  //           {item.description}
  //         </Text>
  //       </div>
  //     </Group>
  //   </UnstyledButton>
  // ));

  return (
    <Box style={{ height: '100%' }}>
      <header className={classes.header}>
        <Group justify="space-between" h="100%">
          {/* <MantineLogo size={30} /> */}
          <Image width='80px' height='25px' src={companyname} />
          <Container
            fluid
            mx="0"
            className={classes.mainSection}
            size="md"
            visibleFrom="md"
          >
            <Group justify="end">
              <Menu
                width={260}
                position="bottom-end"
                transitionProps={{ transition: "pop-top-right" }}
                onClose={() => setUserMenuOpened(false)}
                onOpen={() => setUserMenuOpened(true)}
                withinPortal
              >
                <Menu.Target>
                  <UnstyledButton
                    className={cx(classes.user, {
                      [classes.userActive]: userMenuOpened,
                    })}
                  >
                    <Group gap={7}>
                      <IconUserCircle color='#B3F9FF' radius="xl" size={20} />

                      <Text fw={500} size="sm" lh={1} mr={3} color="#B3F9FF">
                        {name}
                      </Text>
                      {userMenuOpened ? (
                        <IconChevronUp
                          style={{ width: rem(12), height: rem(12) }}
                          stroke={1.5}
                          color='#B3F9FF'
                        />
                      ) : (
                        <IconChevronDown
                          style={{ width: rem(12), height: rem(12) }}
                          stroke={1.5}
                          color='#B3F9FF'
                        />
                      )}
                    </Group>
                  </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    leftSection={
                      <IconLogout
                        style={{ width: rem(16), height: rem(16) }}
                        stroke={1.5}
                      />
                    }
                    onClick={handleLogout}
                  >
                    Logout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Container>

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            hiddenFrom="md"
            color="cyan"
            size={70}
          />
        </Group>
      </header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="30%"
        padding="md"
        title={<Image width='80px' height='25px' src={companyname} />}
        zIndex={1000000}
        bg={'#a4e5f8'}
      
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <NavBar />
        </ScrollArea>
      </Drawer>
    </Box>
  );
};

export default DoctorHeader;
