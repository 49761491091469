import cx from "clsx";
import {
  Group,
  UnstyledButton,
  Text,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  rem,
  useMantineTheme,
  Menu,
  Container,
  Image,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import companyname from "../../../assets/company-name.png";
import {
  IconChevronDown,
  IconChevronUp,
  IconLogout,
  IconSettings,
  IconUserCircle,
} from "@tabler/icons-react";
import classes from "./HeaderMegaMenu.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../NavBarLinksGroup/NavBar";
import loginService from "../../../services/loginService";
import { AdminLogin } from "../../../constants/constants";

const AdminHeader = () => {
  const navigate = useNavigate();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const theme = useMantineTheme();

  const [opened, { toggle }] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [name, setName] = useState(localStorage.getItem("namefield"));

  const handleLogout = () => {
    loginService.dologout("admin");
    navigate(AdminLogin, { replace: true });
  };


  //   <UnstyledButton className={classes.subLink} key={item.title}>
  //     <Group wrap="nowrap" align="flex-start">
  //       <ThemeIcon size={34} variant="default" radius="md">
  //         <item.icon
  //           style={{ width: rem(22), height: rem(22) }}
  //           color={theme.colors.blue[6]}
  //         />
  //       </ThemeIcon>
  //       <div>
  //         <Text size="sm" fw={500}>
  //           {item.title}
  //         </Text>
  //         <Text size="xs" c="dimmed">
  //           {item.description}
  //         </Text>
  //       </div>
  //     </Group>
  //   </UnstyledButton>
  // ));

  return (
    <Box style={{ height: "100%" }}>
      <header className={classes.header}>
        <Group justify="space-between" h="100%">
          {/* <MantineLogo size={30} /> */}
          <Image width="80px" height="25px" src={companyname} />
          <Container
            fluid
            mx="0"
            className={classes.mainSection}
            size="md"
            visibleFrom="sm"
          >
            <Group justify="end">
              {/* <MantineLogo size={28} /> */}

              <Menu
                width={260}
                position="bottom-end"
                transitionProps={{ transition: "pop-top-right" }}
                onClose={() => setUserMenuOpened(false)}
                onOpen={() => setUserMenuOpened(true)}
                withinPortal
              >
                <Menu.Target>
                  <UnstyledButton
                    className={cx(classes.user, {
                      [classes.userActive]: userMenuOpened,
                    })}
                  >
                    <Group gap={7}>
                      <IconUserCircle color="#B3F9FF" radius="xl" size={20} />

                      <Text fw={500} size="sm" lh={1} mr={3} color="#B3F9FF">
                        {name}
                      </Text>
                      {userMenuOpened ? (
                        <IconChevronUp
                          style={{ width: rem(12), height: rem(12) }}
                          stroke={1.5}
                          color="#B3F9FF"
                        />
                      ) : (
                        <IconChevronDown
                          style={{ width: rem(12), height: rem(12) }}
                          stroke={1.5}
                          color="#B3F9FF"
                        />
                      )}
                    </Group>
                  </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>Settings1</Menu.Label>
                  <Menu.Item
                    leftSection={
                      <IconSettings
                        style={{ width: rem(16), height: rem(16) }}
                        stroke={1.5}
                      />
                    }
                  >
                    Account settings
                  </Menu.Item>

                  <Menu.Item
                    leftSection={
                      <IconLogout
                        style={{ width: rem(16), height: rem(16) }}
                        stroke={1.5}
                      />
                    }
                    onClick={handleLogout}
                  >
                    Logout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Container>

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            hiddenFrom="sm"
            // p={"lg"}
            m={"sm"}
          />
        </Group>
      </header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Welcome"
        hiddenFrom="sm"
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
          <NavBar />
        </ScrollArea>
      </Drawer>
    </Box>
  );
};

export default AdminHeader;
