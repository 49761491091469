import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import TrussLogo from "./truss-logo/TrussLogo"
import patientService from '../../services/patientService'
import Question from "./Components/Questions";
import UserInput from "./Components/UserInput";
import dayjs from "dayjs";

import ArrowRightIcon from "./arrow-right.svg";
import { HeaderMenu } from '../../components/Header/HeaderMenu';
import { BreadCampContext } from '../../context/BreadCampContext';
import { Box, Title, Button, Group, TextInput, Select, MultiSelect, Center } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';
import moment from 'moment';
const PatientForm = () => 
    {
    const navigate = useNavigate();
    const location = useLocation();
    const { setBreadCampList } = useContext(BreadCampContext);
    const [questionNumber, setQuestionNumber] = useState(0);
    const myProps = location.state;
    const [questions, setQuestionList] = useState([])
    const [userAnswers, setUserAnswers] = useState([]);

    const [activeStep, setActiveStep] = useState(0);
    const [value, setValue] = useState(null);

    const form = useForm({
        initialValues: {
            dateofsurgery: null,
            surgerycategory: [],
            surgerytype: [],
            pre_exis_con: [],
            height: "",
            weight: "",
            weighttype: [],
            heighttype: [],
            gender: "",
            dob: null,
            address: "",
            city: "",
        },


        validate: {
            dateofsurgery: (value) =>
                value === null || !dayjs(value).isBefore(dayjs().startOf('day'))
                    ? 'Date must be before today'
                    : null,

            surgerycategory: (value) => (value.length >= 1 ? null : 'Field Is Required!'),
            surgerytype: (value) => (value.length >= 1 ? null : 'Field Is Required!'),
            pre_exis_con: (value) => (value.length >= 1 ? null : 'Field Is Required!'),
            height: (value) => (value.length < 1 ? 'Field Is Required!' : null),
            weight: (value) => (value.length < 1 ? 'Field Is Required!' : null),
            weighttype: (value) => (value.length >= 1 ? null : 'Field Is Required!'),
            heighttype: (value) => (value.length >= 1 ? null : 'Field Is Required!'),
            gender: (value) => (value.length < 2 ? 'Field Is Required!' : null),
            dob: (value) =>
            value === null || !dayjs(value).isBefore(dayjs().startOf('day'))
                ? 'Date must be before today'
                : null,
            // address: (value) => (value.length < 2 ? 'Name must have at least 2 letters' : null),
            // city: (value) => (value.length < 2 ? 'Name must have at least 2 letters' : null),

            // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            // address: (value) => (value.length < 5 ? 'Address must have at least 5 letters' : null),
        },
    });
    const createPatient = async () => {
        const apidata = {
            surgerydate: moment(form.values.dateofsurgery).format("DD-MM-YYYY"),
            gender: form.values.gender,
            surgerytype: form.values.surgerycategory,
            surgerysubtype: form.values.surgerytype,
            height: form.values.height,
            weight: form.values.weight,
            Preexistingcon: form.values.pre_exis_con,
            operationtype: "",
            address: "",
            city: "",
            dob: moment(form.values.dob).format("DD-MM-YYYY"),
            weighttype: form.values.weighttype,
            heighttype: form.values.heighttype,
        }
        console.log(apidata)

        const dateOfSurgery = apidata.surgerydate;
        const currentdate = moment()
        const surgeryDate = moment(dateOfSurgery);
        const day = surgeryDate.diff(currentdate, "days");
        // const condition =
        //     day > 0 ? "preSurgery" : "postSurgery";
        // if (day === 0) {
        //     // same day surgery condition
        //     navigate("/guidance", {
        //         state: {
        //             condition
        //         },
        //         replace: true,
        //     });
        // }
        // else if (day > 0) {
        //     // post surgery condition
        //     // navigate("/guidance", {
        //     //   state: {
        //     //     condition
        //     //   },
        //     //   replace: true,
        //     // });
        //     navigate('/patientDashboard/ImageAI')
        // }
        // else if (day < 0) {
        //     // pre surgery condition
        //     // navigate("/patientDashboard/pain-data");
        //     navigate('/patientDashboard/ImageAI')
        // }
        // const apicall = await patientService.postPatientQuestionAnswerfordatacollector(apidata)
        // console.log(apicall)
    }
    const nextStep = () => {
        if (activeStep === 0) {
            setActiveStep(1);
            console.log(steps)

        }
        else if (activeStep === 1) {
            // if (form.validateField('address')) {
            setActiveStep(2);
            //  }
        } else {
            setActiveStep((current) => (current < 2 ? current + 1 : current));
        }
    };

    const prevStep = () => setActiveStep((current) => (current > 0 ? current - 1 : current));

    const handleSubmit = async (values) => {
        console.log(values);
        //nextStep();
        // const apidata = {
        //     surgerydate: dayjs(values.dateofsurgery).format("YYYY-MM-DD"),
        //     gender: values.gender,
        //     surgerytype: values.surgerycategory,
        //     surgerysubtype: values.surgerytype,
        //     height: values.height,
        //     weight: values.weight,
        //     Preexistingcon: values.pre_exis_con,
        //     operationtype: "",
        //     address: "",
        //     city: "",
        //     dob: dayjs(values.dob).format("YYYY-MM-DD"),
        //     weighttype: values.weighttype,
        //     heighttype: values.heighttype,
        // }
        // const apicall = await patientService.postPatientQuestionAnswerfordatacollector(apidata)
        // console.log(apicall)
        nextStep()

    };

    useEffect(() => {
        setBreadCampList([
            { title: "Patient Health Question" },
        ])
        const fetchQuestions = async () => {
            try {
                const response = await patientService.getQuestionList();
                console.log(response)
                setQuestionList(response.data[0].assignquestion);
            } catch (error) {
                console.error("Error fetching questions:", error);
            }
        };

        fetchQuestions();
        form.setFieldValue("weighttype", "kg")
        form.setFieldValue("heighttype", "CM")

    }, []);
    function nextQuestion() {
        if (questionNumber === questions.length - 1) {
            submitForm();
            return;
        }

        setQuestionNumber(Math.min(questionNumber + 1, 3));
    }

    function prevQuestion() {
        setQuestionNumber(Math.max(questionNumber - 1, 0));
    }





  async function submitForm() {
    try {
      console.log("SUBMITTED", userAnswers);
      if (userAnswers.length === 4) {
        const data = await patientService.postPatientQuestionAnswer(
          userAnswers
        );
        console.log(data)
        if (data.status == 201) {
          // show notification message Create Success and check surgery date condition
          const dateOfSurgery = userAnswers[0]?.answer;
          const currentdate = moment()
          const surgeryDate = moment(dateOfSurgery);
          const day = surgeryDate.diff(currentdate, "days");
          const condition =
            day > 0 ? "preSurgery" : "postSurgery";
          if (day === 0) {
            // same day surgery condition
            navigate("/guidance", {
              state: {
                condition
              },
              replace: true,
            });
          }
          else if (day > 0) {
            // post surgery condition
            // navigate("/guidance", {
            //   state: {
            //     condition
            //   },
            //   replace: true,
            // });
            navigate('/patientDashboard/ImageAI')
          }
          else if (day < 0) {
            // pre surgery condition
           // navigate("/patientDashboard/pain-data");
            navigate('patientDashboard/ImageAI')
          }

        }
    }

else {
    // enter all question first error show
}

} catch (err) {
console.log(err);
}
  }
  const steps = [
    {
        label: 'Patient Health Question',
        content: (
            <>
                <DatePickerInput
                    withAsterisk
                    label="Surgery Date"
                    placeholder="Pick date"
                    maxDate={dayjs().subtract(1, 'day').endOf('day').toDate()}
                    {...form.getInputProps("dateofsurgery")}

                />
                <Select
                    withAsterisk
                    label="Gender"
                    placeholder="Pick value"
                    data={['MALE', "FEMALE"]}
                    {...form.getInputProps("gender")}
                />
                <Select
                    withAsterisk
                    label="Surgery Type"
                    placeholder="Pick value"
                    data={['Knee']}
                    {...form.getInputProps("surgerycategory")}
                />
                <MultiSelect
                    withAsterisk
                    label="Surgery Type Part"
                    placeholder="Pick value"
                    data={['TKR LFT', 'TKR Right']}
                    {...form.getInputProps("surgerytype")}
                />
                <MultiSelect
                    withAsterisk
                    label="PRE-EXISTING CON"
                    placeholder="Pick value"
                    data={['Cancer', 'Diabetes', 'High blood pressure', 'Congestive Heart Failure', 'Allergies']}
                    {...form.getInputProps("pre_exis_con")}
                />
                <DatePickerInput
                    withAsterisk
                    label="Date Of Birth"
                    placeholder="Pick date"
                    maxDate={dayjs().subtract(1, 'day').endOf('day').toDate()}
                    {...form.getInputProps("dob")}
                />
                <Group d="flex" justify='space-between' gap={0}>
                    <TextInput w={"70%"}
                        withAsterisk
                        label="Height"
                        placeholder="Height"
                        {...form.getInputProps('height')}
                    />
                    <Select w={"28.5%"}
                        withAsterisk
                        label="Height"
                        placeholder="Pick value"
                        data={['CM', "FEET"]}
                        {...form.getInputProps("heighttype")}
                    />

                </Group>
                <Group d="flex" justify='space-between' gap={0}>
                    <TextInput w={"70%"}
                        withAsterisk
                        label="Weight"
                        placeholder="Weight"
                        {...form.getInputProps('weight')}
                    />

                    <Select w={"28.5%"}
                        label="Pick Value"
                        placeholder="Weight"


                        data={[
                            { value: 'kg', label: 'KG' },
                            { value: 'lbs', label: 'lbs' },

                        ]}
                        {...form.getInputProps("weighttype")}

                    />

                </Group>


            </>
        ),
    },
    {
        label: 'Review and Submit',
        content: (
            <Box>
                <p style={{ color: "#ffff", display: 'flex', justifyContent: 'space-between' }} ><strong style={{ textAlign: "left" }}>Surgery Date:</strong> {moment(form.values.dateofsurgery).format('DD-MM-YYYY')}</p>
                <p style={{ color: "#ffff", display: 'flex', justifyContent: 'space-between' }}><strong>Surgery Category:</strong> {form.values.surgerycategory}</p>
                <p style={{ color: "#ffff", display: 'flex', justifyContent: 'space-between' }}><strong>Surgery Type:</strong> {form.values.surgerytype.join(", ")}</p>
                <p style={{ color: "#ffff", display: 'flex', justifyContent: 'space-between' }}><strong>Gender:</strong> {form.values.gender}</p>
                <p style={{ color: "#ffff", display: 'flex', justifyContent: 'space-between' }}><strong>PRE-EXISTING CONDITION:</strong> {form.values.pre_exis_con.join(", ")}</p>
                <p style={{ color: "#ffff", display: 'flex', justifyContent: 'space-between' }}><strong>Date Of Birth:</strong> {moment(form.values.dob).format('DD-MM-YYYY')}</p>
                <p style={{ color: "#ffff", display: 'flex', justifyContent: 'space-between' }}><strong>Height:</strong> {form.values.height} {form.values.heighttype}</p>
                <p style={{ color: "#ffff", display: 'flex', justifyContent: 'space-between' }}><strong>Weight:</strong> {form.values.weight}  {form.values.weighttype}</p>
            </Box>
        ),
    },
];
return (
    <Center >


        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            {/* <Title c={"#ffff"} align="center" mb="sm">Patient Form</Title> */}
            <Box mb="sm">
                <Title align="center" order={2} c={"#ffff"}>{steps[activeStep].label}</Title>
                {steps[activeStep].content}
            </Box>

            <Group position="left" mt="md">
                {activeStep !== 0 && (
                    <Button variant="default" onClick={prevStep}>
                        Back
                    </Button>
                )}
                {activeStep < steps.length - 1 ? (
                    <Button type="submit" fullWidth >Next step</Button>
                ) : (
                    <Button fullWidth onClick={createPatient}>Submit</Button>
                )}
            </Group>
        </form>
    </Center>
)
}

export default PatientForm