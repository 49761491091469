import React, { useEffect, useState,useContext } from 'react';
import dataCollectorService from '../../../services/dataCollectorServices';
import PatientService from '../../../services/patientService';
import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    rem,
    keys,
    Grid,
    Button,
    Tooltip,
} from "@mantine/core";
import { Helmet } from "react-helmet-async";
import BreadCamp from '../../../resuable/BreadCamps';
import {
    IconSelector,
    IconCirclePlus,
    IconChevronDown,
    IconChevronUp,
    IconSearch,
    IconPencil,
    IconTrash,
    IconEye,
} from "@tabler/icons-react";
import PatientTable from './PatientTable';
import { BreadCampContext } from "../../../context/BreadCampContext";
import AssignPatientFormDataCollector from './AssignPatientForm';
import { useNavigate } from 'react-router-dom';
const DataCollector = () => {
    const navigate=useNavigate()
    const [rows, setRows] = useState([]);
    const [patientListshow, setPatientListshow] = useState();
    const { setBreadCampList } = useContext(BreadCampContext)
    const [list,setList]=useState([]);
    const [name,setName]=useState('');
    const [collectorid,setCollectorId]=useState();
    const [ids,setIds]=useState([])
    const clickcreateicon=(name,id,ids)=>{
        setPatientListshow(false) 
        console.log(patientListshow) 
        setName(name)
        setCollectorId(id)
        setIds(ids)
    }
    const Fetchlist = async () => {
        const apicall = await dataCollectorService.getlist()
        console.log(apicall)
        if (apicall?.statusCode === 200) {
            if (apicall?.data?.length > 0) {
                const setdata = []
                apicall?.data?.forEach((ec) => {
                    const data = {
                        name: ec?.name ? ec.name : "",
                        email: ec?.email ? ec.email : "",
                        mobile: ec?.mobile ? ec.mobile : "",
                        id:ec?._id ? ec._id :"",
                        patientid:ec?.patientids?.length>0 ? ec?.patientids:[] 
                    }
                    setdata.push(data)
                })
                console.log(setdata)
                const dataselect=[]
                setdata.forEach((ed)=>{
                    dataselect.push({value:ed.id,label:ed.name})
                })
                fetchPatientList()
                const rowdata = setdata.map((row, index) => (
                    <Table.Tr key={index}>
                        <Table.Td>{index + 1}</Table.Td>
                        <Table.Td>{row.name}</Table.Td>
                        <Table.Td>{row.email}</Table.Td>
                        <Table.Td>{row.mobile}</Table.Td>
                        <Table.Td>
                            <Group align="">
                                <Tooltip label="Assign Patient">
                                    <IconCirclePlus
                                        size={18}
                                        color="#90EE90"
                                        onClick={()=>clickcreateicon(row.name,row.id,row.patientid)}
                                    />
                                </Tooltip>
                                <Tooltip label="Delete Data Collector">
                                    <IconTrash
                                        size={18}
                                        color="red"
                                    />
                                </Tooltip>
                            </Group>
                        </Table.Td>
                    </Table.Tr>
                ));
                setRows(rowdata);
                setPatientListshow(true)
            }   
        }
   
    }
    const fetchPatientList=async ()=>{
        const apiacll = await PatientService.getallPatients();

        if (apiacll.statusCode === 200) {
          console.log(apiacll);
          const setdata = apiacll.data;
          const filterkeydata = [];
          setdata.forEach((element) => {
            filterkeydata.push({
              label: element.patientid,
              value: element._id
            });
          });
          setList(filterkeydata)
        //   setSortedData(filterkeydata);
        }
    }
    useEffect(() => {
        setBreadCampList([{ title: "Data Collector List", handleclick: '' }]);
        Fetchlist()
    }, [])
    const ClickCreatePatient=()=>{
        navigate("/CoordinatorDashboard/createdatacollector")
    }
    return (
        <>
            <Helmet>
                Data Collector List
            </Helmet>
            <Grid>
                <Grid.Col>
                    <Group style={{ justifyContent: "space-between" }}>
                        <BreadCamp  />
                        {patientListshow===true || !patientListshow ?
                         <Button
                               onClick={ClickCreatePatient}
                                fw={600}
                                leftSection={<IconCirclePlus size={20} />}
                                variant="default"
                            >
                                Create Data Collector
                            </Button>
                            :""}
                    </Group>
                </Grid.Col>
                <Grid.Col>
                    {patientListshow === true ? (
                        <PatientTable
                            rows={rows}
                            sortedData={rows}
                        />
                    ) : (
                       <>
                        {patientListshow===false ?
                        <AssignPatientFormDataCollector ids={ids} collectorid={collectorid} setPatientListshow={setPatientListshow} name={name} list={list} />
                        :""}
                       </>
                    )}
                </Grid.Col>
            </Grid>
        </>
    )
}
export default DataCollector;