import { useEffect, useState,useContext } from "react";
import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    rem,
    keys,
    Grid,
    Button, Tooltip
} from "@mantine/core";
import {
    IconSelector,
    IconCirclePlus,
    IconChevronDown,
    IconChevronUp,
    IconSearch,
    IconPencil,
    IconTrash,
    IconUpload,
} from "@tabler/icons-react";
import PatientService from "../../../services/patientService";
import PatientTable from "./PatientTable";
import BreadCamp from "../../../resuable/BreadCamps";
import AddEditPatient from "./CreatePatient";
import { Link, useNavigate } from "react-router-dom";
import { Patientassignexersise } from '../../../constants/constants';
import {navbarContext} from '../context/DataCollectorDashboard';
import {BreadCampContext} from '../../../context/BreadCampContext';

function PatientList(props) {
    const { setBreadCampList} = useContext(BreadCampContext);
    const {setNavActive}=useContext(navbarContext)
    const [sortedData, setSortedData] = useState([]);
    const [list, setList] = useState([]);
    const [rows, setRows] = useState([]);
    const [patientListshow, setPatientListshow] = useState(true);
    const [createpatientshow, setCreatepatientshow] = useState(false);
    const navigate = useNavigate();
    const [breadcampitems, setBreadcampitems] = useState([
        // { title: "DashBoard", href: "#" },
        { title: "Patient List", href: "#" },
    ]);
    const clickopenshowform= (id) => {
            navigate(`/datacollector/datacollectform/${id}`)
    }
    const FetchPatientList = async () => {
        const apiacll = await PatientService.getallPatients();

        if (apiacll.statusCode === 200) {
            console.log(apiacll);
            const setdata = apiacll.data;
            const filterkeydata = [];
            setdata.forEach((element) => {
                filterkeydata.push({
                    name: element.name,
                    email: element.email,
                    mobile: element.mobile,
                    id: element._id,
                    active:element.activepatient===true ? "Active":"InActive"
                });
            });
            console.log(filterkeydata);
            setList(filterkeydata);
            setSortedData(filterkeydata);
            const rowdata = filterkeydata.map((row, index) => (
                <Table.Tr key={index}>
                    <Table.Td>{index + 1}</Table.Td>
                    <Table.Td>row.name</Table.Td>
                    <Table.Td>row.email</Table.Td>
                    <Table.Td>row.mobile</Table.Td>
                    <Table.Td>row.active</Table.Td>

                    <Table.Td>
                        <Group align="">
                            <Tooltip label='Patient Daily Workout Data Form'><IconUpload onClick={()=>clickopenshowform(row.id)} size={18} color="#90EE90" /></Tooltip>
                            {/* <Tooltip label='Assign Exercise'><IconCirclePlus onClick={() => { navigate(`${Patientassignexersise}/${row.id}`) }} size={18} color="#90EE90" /></Tooltip> */}
                            {/* <IconTrash size={18} color="red" /> */}
                            {/* <IconEye size={18} color="#90EE90" /> */}
                        </Group>
                    </Table.Td>
                </Table.Tr>
            ));
            setRows(rowdata);
        }
    };
    useEffect(() => {
        setBreadCampList([
            { title: "Patient List", route: "/datacollector/patientlist" },
        ])
        setNavActive('Patients')
        FetchPatientList();
    }, []);

    const dataObject = {
        key1: "value1",
        key2: "value2",
    };

    // useEffect(() => {
    //     const rowdata = sortedData.map((row, index) => (
    //         <Table.Tr key={index}>
    //             <Table.Td>{index + 1}</Table.Td>
    //             <Table.Td>{row.name}</Table.Td>
    //             <Table.Td>{row.email}</Table.Td>
    //             <Table.Td>{row.mobile}</Table.Td>
    //             <Table.Td>{row.active==="InActive" ? "Patient Setup Pending":row.active}</Table.Td>
    //             <Table.Td>
    //                 <Group align="">
    //                     {/* <Tooltip label='view Patient Detail'><IconTrash size={18} color="red" /></Tooltip> */}
    //                     <Tooltip label='Assign Exercise'><IconCirclePlus
    //                         onClick={() => { navigate(`${Patientassignexersise}/${row.id}`) }} size={18} color="#90EE90" /></Tooltip>

    //                     {/* <Tooltip label='view Patient Detail'><IconEye size={18} color="#90EE90" /></Tooltip> */}

    //                 </Group>
    //             </Table.Td>
    //         </Table.Tr>
    //     ));
    //     setRows(rowdata);
    // }, [sortedData]);
    const ClickCreatePatient = () => {
        navigate('/doctorDashboard/add-patient')
        // setBreadcampitems([
        //   { title: "DashBoard", href: "#" },
        //   { title: "Patient List", href: "#" },
        //   { title: "Create Patient", href: "#" },
        // ]);
        // setCreatepatientshow(true);
        // setPatientListshow(false);
    };
    return (
        <Grid>
            <Grid.Col>
                <Group style={{ justifyContent: "space-between" }}>
                    <BreadCamp data={breadcampitems} />
                    {/* {createpatientshow === true ? (
                        ""
                    ) : (
                        <Button
                            onClick={ClickCreatePatient}
                            fw={600}
                            leftSection={<IconCirclePlus size={20} />}
                            variant="default"
                        >
                            Create Patient
                        </Button>
                    )} */}
                </Group>
            </Grid.Col>
            <Grid.Col>
                {patientListshow === true ? (
                    <PatientTable
                        list={list}
                        rows={rows}
                        sortedData={rows}
                        setSortedData={setSortedData}
                    />
                ) : (
                    ""
                )}
                {createpatientshow === true ? <AddEditPatient /> : ""}
            </Grid.Col>
        </Grid>
    );
}
export default PatientList;
