import Axios from "../helper/patientHelper";
import { BASEURL } from "../constants/constants";

const axios = new Axios();
const baseUrl = BASEURL;

class DailyReportService {
  getDailyReport() {
    const option = {
      url: `${baseUrl}/patientdailyreport`,
    };
    return axios.get(option);
  }
  getPatientRiskdata(data) {
    const option = {
      url: `${baseUrl}/patient-risk-data`,
      data,
    };
    return axios.post(option);
  }
  getDailyReportbyPatientId(data) {
    const option = {
      url: `${baseUrl}/patientdailyreport/${data}`,
    };
    return axios.get(option);
  }
  getSignedUrl(data) {
    const option = {
      url: `${baseUrl}/getImageSignedUrl`,
      data,
    };
    return axios.post(option);
  }
  getThermalSignedUrl(data) {
    const option = {
      url: `${baseUrl}/getthermalsignurl`,
      data,
    };
    return axios.post(option);
  }
}

const dailyReportService = new DailyReportService();
export default dailyReportService;
