import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import {
  Container,
  Image,
  Title,
  Paper,
  Center,
  Box,
  Text,
} from "@mantine/core";
import "./AdminLogin.scss";
import LoginForm from "../sections/auth/login/LoginForm.jsx";
import { Grid } from "@mantine/core";
import TopLeftSvg from "../assets/left-top.svg";
import RightBottomSvg from "../assets/right-bottom.svg";
import clogo from "../assets/TrussLogo.png";
import companyname from "../assets/company-name.png";

const OverlayContainer = styled.div`
  position: relative;
`;
const OverlayImage = styled(Image)`
  position: absolute;
  top: 50%; /* Move to vertical center */
  left: 50%; /* Move to horizontal center */
  transform: translate(-50%, -50%); /* Center the image */
  width: 70%;
  height: 70%;
`;
const OverlayImageMobile = styled(Image)`
  position: absolute;
  left: 50%; /* Move to horizontal center */
  transform: translate(-50%, -50%); /* Center the image */
  width: 70%;
  height: 70%;
`;
const OverlayImage2 = styled(Image)`
  position: absolute;
  top: 75%; /* Move to vertical center */
  left: 50%; /* Move to horizontal center */
  transform: translate(-50%, -50%); /* Center the image */
  width: 70%;
  height: 70%;
`;
const OverlayContainerMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const OverlayContent = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function DataCollectorLogin() {
  return (
    <>
      <Helmet>
        <title> Patient Family Login | Truss Health </title>
      </Helmet>
      <Container p={0} fluid={true} className="main-container">
        <Grid p={0}>
          <Grid.Col
            visibleFrom="sm"
            span={6}
            style={{ position: "relative", height: "100vh" }}
          >
            <div style={{ position: "absolute", top: "0px", width: "100%" }}>
              <Image src={TopLeftSvg} />
            </div>
            <Center style={{ height: "100%", width: "100%" }}>
              <Box>
                <Image
                  src={clogo}
                  height="150px"
                  width="100px"
                  style={{ objectFit: "fill" }}
                />
                <Image mt={10} src={companyname} height="40px" />
                <Text size="xl" align="center" color="#B3F9FF" fw={600}>
                  Welcomes You
                </Text>
              </Box>
            </Center>
          </Grid.Col>
          {/* This below component is visble in Desktop Mode */}
          <Grid.Col visibleFrom="sm" fluid span={6}>
            <div style={{ position: "absolute", bottom: "0px", width: "" }}>
              <Image src={RightBottomSvg} />
            </div>
            <Center style={{ height: "100%", width: "100%" }}>
              <Box w={"70%"}>
                <Title
                  data-testid="PatientLoginLabel"
                  c="#ffff"
                  align="center"
                  visibleFrom="sm"
                >
                  Data Collector Login
                </Title>
                <LoginForm userType="datacollector" />
              </Box>
            </Center>
          </Grid.Col>
          {/* This below component is visble in Mobile Mode */}
          <Grid.Col hiddenFrom="sm"  span={12}>
            <div style={{ position: "absolute", top: "0px", width: "100%" }}>
              <Image src={TopLeftSvg} />
            </div>
            <div style={{ position: "absolute", bottom: "0px", width: "" }}>
              <Image src={RightBottomSvg} />
            </div>
            <Center style={{ height: "100vh", width: "100%", marginTop: "" }}>
              <Box w={"100%"}>
                <Center>
                  <Title c={"#ffff"} order={4}>
                    TrussHealth
                  </Title>
                </Center>
                <Center>
                  <Title
                    data-testid="PatientLoginLabelMobile"
                    c={"#ffff"}
                    order={5}
                  >
                    Data Collector Login{" "}
                  </Title>
                </Center>
                <LoginForm userType="datacollector" />
              </Box>
            </Center>
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
}
