import Cookies from "universal-cookie";
import * as jose from "jose";
import Axios from "../helper/patientHelper";
import { BASEURL } from "../constants/constants";

const axios = new Axios();
const cookies = new Cookies();

const storePainInfo = (data, id) => {
  const option = {
    url: `${BASEURL}/painInfo/${id}`,
    data,
  };

  return axios.post(option);
};

const StoreRangeOfMotion = (data, id) => {
  const option = {
    url: `${BASEURL}/rangeofmotion`,
    data,
  };

  return axios.post(option);
};

//Post request for image AI
const StoreImageAI = (data) => {
  const option = {
    url: `${BASEURL}/patientdailyimageAI`,
    data,
  };

  return axios.post(option);
};

const StorePainData = (data) => {
  const option = {
    url: `${BASEURL}/pain`,
    data,
  };

  return axios.post(option);
};

export { storePainInfo, StoreRangeOfMotion, StoreImageAI, StorePainData };
