import Axios from "../helper/axiosHelper";
import { BASEURL } from "../constants/constants";

const axios = new Axios();
const baseUrl = BASEURL;

class ThermalService {
    create(data) {
        const option = {
          url: `${baseUrl}/thermaldata`,
          data,
        };
        return axios.post(option);
      }
      geticidata(thermalID) {
        const option = {
          url: `${baseUrl}/thermaldata/${thermalID}`,
        };
        return axios.get(option);
      }
      manualdata(data) {
        const option = {
          url: `${baseUrl}/manualthermaldatasave`,
          data,
        };
        return axios.post(option);
      }
      manualdataici(data) {
        const option = {
          url: `${baseUrl}/manualthermaldatasave_ici`,
          data,
        };
        return axios.post(option);
      }
      uploadthermalfile(data) {
        const option = {
          url: `${baseUrl}/thermalfileupload`,
          data,
        };
        return axios.post(option);
      }
    }
// export default ThermalService;

const thermalService = new ThermalService();
export default thermalService;