import styled from "styled-components";

export const ProgressBarContainer = styled.div`
flex: 0 0 25%; // Assign flex-basis to 25%, no grow, no shrink. This controls the width.
display: flex;
align-items: center;
justify-content: center;
position: relative; 
margin: 2%;
// background: rgba(255,255,255,0.5);
background: rgba(1, 82, 106, 0.5);
border-radius: 50%;
box-shadow: 0 2px 4px rgba(0,0,0,0.1);
overflow: hidden;

&:before {
  content: "";
  display: block;
  padding-top: 100%; // This creates a square aspect ratio, maintaining the circle.
}

.circular-progressbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}  
`;
  export  const CenteredContent = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    `;
    
   export const BackgroundContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; // Centers children in the cross-axis
    justify-content: center;
    background: rgba(255,255,255,0.1);
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin: 20px 0px;
    padding: 20px 5px;
    width: 90%;
    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between; // Adjust if needed
        width: 100%;
    }
    `;


   export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40px, 2fr)); // Adjust minmax for desired column width
    gap: 20px;
    width: 100%;
    
    @media (min-width: 470px) {
        // For larger screens, adjust as needed, e.g., three columns
        grid-template-columns: repeat(3, 1fr);
    }
    `;


       export const SectionContainer = styled.div`
    display: flex;
    flex: ${props => props.flexSize || 1};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin: 20px 10px;
    padding: 20px;
    width: 100%;

    @media (min-width: 768px) {
        // Use flexSize to control width if provided, otherwise auto
        width: ${props => props.flexSize ? `calc(${props.flexSize} * 100%)` : 'auto'};
    }
    `;

       export const Heading = styled.h2`
    color: #fff;
    margin-bottom: 20px;
    `;